<template>
  <DialogTitle
    as="h3"
    class="block items-center gap-2 text-lg font-bold leading-6 text-gray-900 capitalize-first"
  >
    {{ title }}
  </DialogTitle>
  <DialogDescription class="flex flex-col gap-2">
    <div v-if="item" class="flex flex-row rounded border p-2">
      <div class="flex w-2/3 flex-col">
        <p v-if="item.remarks.length">{{ item.remarks }}</p>
        <template v-if="itemStock.item_dossier">
          <div class="flex gap-2">
            <span class="inline-block text-slate-700 capitalize-first">
              {{ $t('attributes.price_htva') }} :
            </span>
            <span class="font-semibold">
              {{ $h.formatCurrency(itemStock.item_dossier.price_htva) }}
            </span>
          </div>
          <div class="flex gap-2">
            <span class="inline-block text-slate-700 capitalize-first">
              {{ $t('attributes.price_tvac') }} :
            </span>
            <span class="font-semibold">
              {{ $h.formatCurrency(itemStock.item_dossier.price_tvac) }}
            </span>
          </div>
        </template>
      </div>
      <div class="w-1/3">
        <img :alt="title" :src="item.master_image_url" />
      </div>
    </div>
    <div v-if="dossier" class="flex flex-row rounded border p-2">
      <div class="flex-1">
        <RouterLink
          class="text-primary"
          :to="{
            name: 'dossier_form_edit_route',
            params: {
              id: dossier.id
            }
          }"
          @click="$emit('close')"
        >
          <template v-if="dossier.concernedPerson">
            <h3 class="font-bold">{{ dossier.concernedPerson.full_name }}</h3>
            <h4 class="font-semibold"># {{ dossier.reference }}</h4>
          </template>
          <h3 v-else class="font-bold">{{ dossier.reference }}</h3>
        </RouterLink>
      </div>
    </div>
  </DialogDescription>
</template>

<script>
import { mapActions } from 'vuex'
import { DialogDescription, DialogTitle } from '@headlessui/vue'

export default {
  name: 'ItemStockSummary',
  components: { DialogDescription, DialogTitle },
  props: {
    itemStockId: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      itemStock: null
    }
  },
  computed: {
    title() {
      if (!this.itemStock) {
        return this.$t('global.loading')
      }
      return this.item.name[this.$i18n.locale]
    },
    dossier() {
      return this.itemStock?.item_dossier?.dossier ?? null
    },
    item() {
      return this.itemStock?.item ?? null
    }
  },
  mounted() {
    this.processFetchItemStockAction({
      item_stock_id: this.itemStockId
    }).then((response) => {
      this.itemStock = response.item_stock
    })
  },
  methods: {
    ...mapActions({
      processFetchItemStockAction: 'stock/getItemStock'
    })
  }
}
</script>
