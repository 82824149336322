<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-100" @close="_cancel">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all dark:rounded-md dark:border dark:border-white dark:bg-slate-700 dark:text-white"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 capitalize-first dark:text-red-400"
              >
                {{ title }}
              </DialogTitle>
              <div v-if="message && message.length" class="mt-2">
                <p
                  v-if="!showHtmlTags"
                  class="text-sm text-gray-500 dark:text-white"
                >
                  {{ message }}
                </p>
                <div
                  v-if="showHtmlTags"
                  class="text-gray-500 dark:text-white"
                  v-html="message"
                ></div>
              </div>

              <!-- The template from slot modal_content -->
              <slot name="modal_content" />

              <div
                class="flex flex-row justify-end gap-2"
                :class="
                  (message && message.length) || this.$slots.modal_content
                    ? 'mt-4'
                    : 'mt-8'
                "
              >
                <BaseButton
                  theme-color="bg-secondary text-contrast-secondary dark:border dark:border-secondary dark:bg-slate-700 dark:text-secondary"
                  :title="cancelButtonTitle"
                  @click="_cancel"
                />
                <BaseButton
                  :title="okButtonTitle"
                  theme-color="dark:border dark:border-primary dark:bg-slate-700 dark:text-primary"
                  @click="_confirm"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'ConfirmModal',
  components: {
    BaseButton,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogTitle,
    DialogPanel
  },
  data() {
    return {
      isOpen: false,
      title: 'sans titre',
      message: null,
      okButton: null,
      cancelButton: null,
      resolvePromise: undefined,
      rejectPromise: undefined,
      showHtmlTags: false
    }
  },
  computed: {
    okButtonTitle() {
      return this.okButton ?? 'ok'
    },
    cancelButtonTitle() {
      return this.cancelButton ?? this.$t('button.cancel')
    }
  },
  methods: {
    show(opts = {}) {
      this.title = opts.title
      if (opts.message) {
        this.message = opts.message
      }
      if (opts.okButton) {
        this.okButton = opts.okButton
      }
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      if (opts.showHtmlTags) {
        this.showHtmlTags = opts.showHtmlTags
      }

      this.isOpen = true

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm() {
      this.isOpen = false
      this.resolvePromise(true)
    },

    _cancel() {
      this.isOpen = false
      this.resolvePromise(false)
    }
  }
}
</script>
