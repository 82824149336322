<template>
  <div
    v-if="dashboards.length"
    class="flex flex-col divide-y rounded-md border dark:border-white dark:bg-slate-700 dark:text-white"
  >
    <div
      v-for="dashboard in getUnsubscribedDashboard"
      :key="dashboard.id"
      class="flex flex-col p-2"
    >
      <div class="flex flex-row">
        <h3 class="flex-1 text-base font-bold">
          {{ dashboard.name[auth.language] }}
        </h3>
        <span class="flex-shrink-0">
          <BaseButton
            title="subscribe"
            size="small"
            class="dark:border-primary-500 dark:bg-slate-700 dark:text-primary-300 dark:hover:bg-primary-900"
            @click="() => subscribe(dashboard.id)"
          />
        </span>
      </div>
      <div class="flex flex-row">
        <div class="flex-shrink-0">
          <AvatarComponent
            :user_id="dashboard.creator_id"
            :badge="true"
            size="6"
          />
        </div>
        <div class="flex flex-1 flex-row justify-end">
          <AvatarComponent
            v-for="user_id in dashboard.subscriber_ids"
            :key="user_id"
            :user_id="user_id"
            :badge="false"
            size="6"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import AvatarComponent from '@c/AvatarComponent.vue'
import DashboardTypes from '@/assets/enums/dashboard/DashboardTypes'

export default {
  name: 'DashboardPublic',
  components: { AvatarComponent, BaseButton },
  props: {
    dashboards: {
      type: Array,
      required: true
    },
    typeDashboardAvailable: {
      type: Array,
      required: false,
      default: () => Object.values(DashboardTypes)
    }
  },
  emits: ['subscribed'],
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth',
      dashboardList: 'metadata/getTemplateDashboards'
    }),
    getSubscribedIds() {
      return this.dashboardList.map((item) => item.id)
    },
    getUnsubscribedDashboard() {
      return this.dashboards
        .filter((item) => this.typeDashboardAvailable.includes(item.type))
        .filter((item) => !this.getSubscribedIds.includes(item.id))
    }
  },
  methods: {
    ...mapActions({
      processSubscribeToTemplateDashboardAction:
        'dashboard/subscribeToTemplateDashboard'
    }),
    subscribe(template_dashboard_id) {
      this.processSubscribeToTemplateDashboardAction({
        template_dashboard_id
      }).then((response) =>
        this.$emit('subscribed', response.data.template_dashboard)
      )
    }
  }
}
</script>

<style scoped></style>
