<template>
  <div
    class="h-full overflow-hidden rounded-xl bg-white pb-5 pt-4 dark:border dark:border-white dark:bg-slate-800 dark:text-white"
  >
    <SettingsPageFull>
      <template #body>
        <div class="shrink-0">
          <CatalogNavigationComponent
            v-model:selected-category="selectedCategory"
            v-model:filter="filter"
            :categories="categories"
            :select-item="itemId"
            :is-catalog-modal-open="isCatalogModalOpen"
            @open-print-option="() => (isCatalogModalOpen = true)"
          />
          <div class="mt-2 flex shrink-0 justify-end gap-5">
            <div class="flex w-72 flex-row items-center gap-2">
              <BaseSearchInput v-model="search_value" />
            </div>
            <div class="flex flex-1 flex-row justify-end gap-2">
              <div class="flex flex-1 items-center">
                <BaseButton
                  v-if="itemId"
                  color="warning"
                  icon-type="fas"
                  icon="grid"
                  @click="
                    () => $router.push({ name: 'catalog_list_items_route' })
                  "
                />
              </div>
              <div class="w-60">
                <SelectFuneralHome
                  v-model="selectedFuneralHome"
                  edition-mode
                  required
                  error-key=""
                />
              </div>
              <div class="w-60">
                <SelectComponent
                  v-model="selectedSupplier"
                  :name="name"
                  :label="$t('attributes.supplier')"
                  :values="metadata?.models?.suppliers"
                  display-key="name"
                  :edition-mode="true"
                />
              </div>
              <div v-if="!itemId" class="flex flex-row items-center pt-2">
                <FilterCatalogPanel v-model:filter="filter" />
                <BaseButton
                  icon="print"
                  @click="() => (isCatalogModalOpen = true)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex min-h-0 flex-1 flex-row gap-5 pt-3">
          <div
            :class="[itemId ? 'w-72' : 'w-full']"
            class="@container/list-items"
          >
            <div class="h-full gap-6 overflow-y-auto">
              <div
                class="grid min-h-0 flex-1 grid-cols-1 gap-4 overflow-y-auto @xl/list-items:grid-cols-2 @xl/list-items:gap-4 @4xl/list-items:grid-cols-3 @4xl/list-items:gap-6 @6xl/list-items:grid-cols-4"
              >
                <ItemsCategoryComponent
                  v-for="(item, index) in itemSpecifics"
                  :key="index"
                  :item="item"
                  :funeral-home="selectedFuneralHome"
                  :selected-item="itemId"
                />
              </div>
            </div>
          </div>
          <div v-if="itemId" class="flex-1">
            <SelectedItemCatalogDetailsComponent
              v-model:category="selectedCategory"
              :selected-item="selectedItem"
              :metadata="metadata"
              :section="section"
              :selected-funeral-home="selectedFuneralHome"
            />
          </div>
        </div>
      </template>
    </SettingsPageFull>
  </div>
  <PrintOptionCatalogModal
    v-model:is-modal-open="isCatalogModalOpen"
    :funeral-home="selectedFuneralHome"
    :supplier="selectedSupplier"
    :categories="categories"
    :sort="filter"
  />
</template>
<script>
import SettingsPageFull from '@c/SettingsPageFull.vue'
import { mapActions, mapGetters } from 'vuex'
import ItemsCategoryComponent from '@/views/catalog/components/ItemsCategoryComponent.vue'
import SelectFuneralHome from '@c/InitialStateFormComponent/SelectFuneralHome.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import BaseSearchInput from '@c/addf-package/components/BaseInput/BaseSearchInput.vue'
import PrintOptionCatalogModal from '@/views/catalog/components/PrintOptionCatalogModal.vue'
import CatalogNavigationComponent from '@/views/catalog/components/CatalogNavigationComponent.vue'
import SelectedItemCatalogDetailsComponent from '@/views/catalog/components/SelectedItemCatalogDetailsComponent.vue'
import FilterCatalogPanel from '@/views/catalog/components/FilterCatalogPanel.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'TheItemsCatalogIndexPage',
  components: {
    BaseButton,
    FilterCatalogPanel,
    SelectedItemCatalogDetailsComponent,
    CatalogNavigationComponent,
    PrintOptionCatalogModal,
    BaseSearchInput,
    SelectComponent,
    SelectFuneralHome,
    ItemsCategoryComponent,
    SettingsPageFull
  },
  props: {
    itemId: {
      // recu du router
      type: String,
      required: false,
      default: null
    },
    section: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedCategory: null,
      selectedFuneralHome: null,
      selectedSupplier: null,
      search_value: '',
      open: false,
      isCatalogModalOpen: false,
      items: [],
      categories: [],
      filter: {
        tri: 'name',
        type: 'asc'
      }
    }
  },
  computed: {
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata',
      allCategories: 'metadata/getMetadataItemCategories',
      funeralHomes: 'metadata/getFuneralHomesMetadata'
    }),
    selectedItem() {
      return this.items.find((item) => item.id === this.itemId)
    },
    itemSpecifics() {
      return this.items
        .map((item) => {
          return {
            ...item,
            ...(item.specifics.find(
              (specific) =>
                specific.funeral_home_id === this.selectedFuneralHome
            ) ?? {}),
            id: item.id
          }
        })
        .filter((item) => !item.hidden || item.hidden === 0)
        .filter((item) => item.item_category_id === this.selectedCategory)
        .filter(
          (item) =>
            !this.selectedSupplier || item.supplier_id === this.selectedSupplier
        )
        .filter(
          (item) =>
            this.search_value.length < 2 ||
            item.name[this.$i18n.locale]
              .toLowerCase()
              .includes(this.search_value.toLowerCase())
        )
        .sort((a, b) => {
          let triA, triB
          if (this.filter.tri === 'prix') {
            triA = parseFloat(a.default_price_htva)
            triB = parseFloat(b.default_price_htva)
          } else {
            triA = a.name[this.$i18n.locale]?.toLowerCase()
            triB = b.name[this.$i18n.locale]?.toLowerCase()
          }
          if (this.filter.type === 'desc') {
            return triA > triB ? -1 : triA < triB ? 1 : 0
          } else {
            return triA < triB ? -1 : triA > triB ? 1 : 0
          }
        })
    }
  },
  mounted() {
    this.processFetchItemsForCatalogAction().then((response) => {
      this.items = response.items
      this.categories = response.metadata.categories
      this.selectedFuneralHome = this.funeralHomes?.[0].id ?? null
      this.selectedCategory = this.allCategories?.[0].id ?? null
    })
  },
  methods: {
    ...mapActions({
      processFetchItemsForCatalogAction: 'item/fetchItemsForCatalog'
    })
  }
}
</script>
