<template>
  <div class="flex min-h-0 flex-1 flex-row gap-2">
    <div class="flex h-full w-2/3 flex-col gap-2 pr-3">
      <div class="flex flex-row items-center">
        <div class="flex flex-1 flex-row items-center gap-3">
          <span>
            <BaseButton
              size="small"
              :title="$t('button.back')"
              @click="$router.go(-1)"
            />
          </span>
          <h2 class="shrink-0 text-2xl font-bold capitalize-first">Stock</h2>
        </div>
        <div class="shrink-0">
          <BaseButton
            :theme-color="
              drap_and_drop_enabled
                ? 'bg-secondary text-contrast-secondary'
                : null
            "
            :color="drap_and_drop_enabled ? null : 'dark'"
            :title="
              drap_and_drop_enabled ? $t('button.disable') : $t('button.enable')
            "
            icon="shuffle"
            size="small"
            inverse-icon-order
            @click="drap_and_drop_enabled = !drap_and_drop_enabled"
          />
        </div>
      </div>
      <div class="flex flex-1 flex-col gap-4 overflow-y-auto">
        <div
          v-for="site in getStockablePlace"
          :key="site.id"
          class="flex flex-col gap-2"
        >
          <h3 class="text-xl font-bold">{{ site.name }}</h3>
          <div class="flex flex-col gap-4">
            <LocationStockComponent
              v-for="location in site.locations"
              :key="location.id"
              :location="location"
              :item-location-setting="item_location_setting?.[location.id]"
              :item-stocks="item_stocks?.[location.id] ?? []"
              :drag-and-drop-enabled="drap_and_drop_enabled"
              @move-item-stock="
                (item_stock) => moveItemStock(item_stock, location.id)
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-3 flex w-1/3 flex-col gap-10 rounded-r-lg bg-primary-50/50 p-6"
    >
      <template v-if="item">
        <div class="flex flex-row items-center">
          <h3 class="flex-1 text-xl font-bold">
            {{ item.name[$i18n.locale] }}
          </h3>
          <span class="shrink-0">
            <RouterLink
              :to="{
                name: 'settings_edit_item_route',
                params: { id: item.id }
              }"
            >
              <FontAwesomeIcon :icon="['fal', 'cog']" size="lg" />
            </RouterLink>
          </span>
        </div>
        <p v-if="item.remarks[$i18n.locale]?.length">
          {{ item.remarks[$i18n.locale] }}
        </p>
        <div class="flex h-48 justify-center">
          <img
            v-if="item.master_image_url"
            class="h-full rounded-lg border-slate-700 object-cover shadow-lg shadow-primary-200"
            :src="item.master_image_url"
            :alt="item.name"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import LocationStockComponent from '@/views/stock/item/LocationStockComponent.vue'

export default {
  name: 'TheSummaryPage',
  components: {
    LocationStockComponent,
    BaseButton,
    FontAwesomeIcon
  },
  props: {
    itemId: {
      // recu du router
      type: String,
      required: true
    }
  },
  data() {
    return {
      item: null,
      item_location_setting: null,
      item_stocks: null,
      drap_and_drop_enabled: false
    }
  },
  computed: {
    ...mapGetters({
      locations: 'metadata/getLocationsMetadata',
      sites: 'metadata/getSiteMetadata'
    }),
    getStockablePlace() {
      return this.sites.map((site) => {
        return {
          ...site,
          locations: this.locations.filter(
            (location) =>
              location.site_id === site.id && location.is_storage_location
          )
        }
      })
    }
  },
  mounted() {
    this.getItem()
  },
  methods: {
    ...mapActions({
      processGetItemAction: 'stock/getItem',
      processUpdateStockItemLocationAction: 'stock/updateStockItemLocation'
    }),
    getItem() {
      this.processGetItemAction({
        item_id: this.itemId
      }).then((response) => {
        this.item = response.item
        this.item_location_setting = response.item_location_setting
        this.item_stocks = response.item_stocks
      })
    },
    moveItemStock(item_stock, location_id) {
      if (!this.item_stocks[location_id]) {
        this.item_stocks[location_id] = []
      }
      this.item_stocks = {
        ...this.item_stocks,
        [item_stock.location_id]: this.item_stocks[
          item_stock.location_id
        ].filter((item) => item.id !== item_stock.id),
        [location_id]: [
          ...this.item_stocks[location_id],
          {
            ...item_stock,
            location_id: location_id,
            location: this.locations.find(
              (location) => location.id === location_id
            )
          }
        ]
      }
      this.processUpdateStockItemLocationAction({
        item_stock_id: item_stock.id,
        location_id: location_id
      })
        .then((response) => {
          if (!response.success) {
            this.getItem()
          }
        })
        .catch((error) => {
          this.getItem()
        })
    }
  }
}
</script>
