<template>
  <div
    :class="
      isElementModal ? 'grid grid-cols-10' : 'flex flex-row items-center gap-1'
    "
  >
    <div :class="isElementModal ? 'col-span-1 w-auto' : 'w-24 shrink-0'">
      <SelectComponent
        v-if="index !== 0"
        v-model="localFilter.whereType"
        name="whereType"
        :values="metadata.enums.where_types"
        required
      />
    </div>

    <div :class="isElementModal ? 'col-span-3 w-auto' : 'min-w-0 flex-1'">
      <SelectComponent
        v-if="index === 0 || filter.whereType"
        v-model="localFilter.column"
        name="column"
        :values="filtersAvailable"
        colored
        required
      />
    </div>
    <div :class="isElementModal ? 'col-span-2 w-auto' : 'min-w-0 flex-1'">
      <SelectComponent
        v-if="localFilter.column"
        v-model="localFilter.condition"
        name="condition"
        :values="filteredConditions"
        required
      />
    </div>
    <div :class="isElementModal ? 'col-span-3 w-auto' : 'min-w-0 flex-1'">
      <SelectComponent
        v-if="
          localFilter.condition &&
          selected_column?.type &&
          selected_column?.type === 'select'
        "
        v-model="localFilter.values"
        name="values"
        :values="columnListValues"
        required
        :multiple="['in', 'not_in'].includes(localFilter.condition)"
      />
      <SelectComponent
        v-else-if="
          localFilter.condition &&
          selected_column?.type &&
          selected_column?.type === 'select_colored'
        "
        v-model="localFilter.values"
        name="values"
        :values="columnListValues"
        required
        colored
        :multiple="['in', 'not_in'].includes(localFilter.condition)"
      />
      <BaseShowEditDatePickerv2
        v-else-if="
          selected_column?.type && selected_column?.type === 'select_date'
        "
        v-model:date="localFilter.values"
        edition-mode
      />
      <BaseShowEditDateRangePicker
        v-else-if="
          selected_column?.type && selected_column?.type === 'select_date_range'
        "
        v-model:start="localFilter.values.start"
        v-model:end="localFilter.values.end"
        edition-mode
        required
      />
      <SelectAjax
        v-else-if="
          localFilter.condition &&
          selected_column?.type &&
          (selected_column.type === 'select_wiki_simple' ||
            selected_column.type === 'select_wiki_global')
        "
        v-model:value-object="localFilter.values"
        attribute-label="full_name"
        :url="selected_column.values"
      />
      <SelectUser
        v-else-if="
          selected_column?.type && selected_column.type === 'select_users'
        "
        v-model="localFilter.values"
        name="select_user"
        edition-mode
        :multiple="['in', 'not_in'].includes(localFilter.condition)"
      />
    </div>
    <div
      :class="
        isElementModal ? 'col-span-1 w-full' : 'flex shrink-0 items-center'
      "
    >
      <BaseButton
        v-if="!isForDoctype"
        icon="times"
        color="danger"
        :size="isElementModal ? 'medium' : 'small'"
        @click="() => $emit('delete-line')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import * as _ from 'lodash-es'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import SelectAjax from '@c/SelectAjaxComponent/component/SelectAjax.vue'
import SelectUser from '@c/InitialStateFormComponent/SelectUser.vue'
import BaseShowEditDateRangePicker from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDateRangePicker.vue'
import moment from 'moment'

export default {
  name: 'FilterLineComponent',
  components: {
    BaseShowEditDateRangePicker,
    SelectUser,
    SelectAjax,
    BaseShowEditDatePickerv2,
    SelectComponent,
    BaseButton
  },
  props: {
    filter: {
      type: Array,
      required: true
    },
    filtersAvailable: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    dashboardType: {
      type: String,
      required: false,
      default: ''
    },
    isElementModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:filter', 'delete-line'],
  computed: {
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata'
    }),
    localFilter: {
      get() {
        return this.filter
      },
      set(value) {
        this.$emit('update:filter', value)
      }
    },
    isForDoctype() {
      const invoicingDashboardsTypes = [
        'invoice',
        'purchase_order',
        'quote',
        'credit_note',
        'supplier_order'
      ]
      return (
        invoicingDashboardsTypes.includes(this.dashboardType) &&
        this.filter.column === 'doc_type'
      )
    },
    filteredConditions() {
      let selectedColumn = this.selected_column
      if (typeof selectedColumn === 'undefined') {
        selectedColumn = this.filtersAvailable.find(
          (column) => column.id === this.filter.column
        )
      }
      return this.metadata.enums.filter_conditions.filter((item) =>
        selectedColumn?.conditions.includes(item.id)
      )
    },
    selected_column() {
      if (this.filter.column) {
        return this.filtersAvailable.find(
          (column) => column.id === this.filter.column
        )
      }
      return {}
    },
    columnListValues() {
      let metadatas = _.get(this.metadata, this.selected_column.values)
      for (let key in metadatas) {
        if (!metadatas[key].name && metadatas[key].full_name) {
          metadatas[key].name = metadatas[key].full_name
        }
      }
      return metadatas
    },
    filterPlaceBaseValue() {
      return {
        id: this.filter?.values?.id ?? this.filter?.values?.searchable?.id,
        title: this.filter?.values?.title
      }
    }
  },
  watch: {
    'localFilter.condition'(newValue) {
      if (['in', 'not_in'].includes(newValue)) {
        this.localFilter.values = []
      } else {
        if (this.localFilter.column === 'date_range') {
          this.localFilter.values = {
            start: moment().toDate(),
            end: moment().add(5, 'days').toDate()
          }
        } else {
          this.localFilter.values = null
        }
      }
    },
    'localFilter.column'(newValue) {
      if (newValue === 'date_range') {
        this.localFilter.values = {
          start: moment().toDate(),
          end: moment().add(5, 'days').toDate()
        }
      } else {
        this.localFilter.values = null
      }
    }
  },
  methods: {
    ...mapMutations({ processSetFilterMutation: 'dashboard/setFilter' })
  }
}
</script>
