import apiClient from '@u/apiClient'
import router from '@/router'

import DossierAttachmentsActions from './dossier_attachments/actions'
import DossierPictureActions from './dossier_picture/actions'
import DossierItemsActions from './dossier_items/actions'
import DossierPartnersActions from './dossier_partners/actions'
import DossierPersonActions from './dossier_person/actions'
import DossierPropertiesActions from './dossier_properties/actions'
import DossierSectionUpdateActions from './dossierSectionUpdate/action'
import DossierTaskActions from './dossier_task/actions'
import Promise from 'lodash-es/_Promise'

//const base_errors = { deceased: {}, events: [] }
const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  ...DossierAttachmentsActions,
  ...DossierItemsActions,
  ...DossierPartnersActions,
  ...DossierPersonActions,
  ...DossierPropertiesActions,
  ...DossierSectionUpdateActions,
  ...DossierTaskActions,
  ...DossierAttachmentsActions,
  ...DossierPictureActions,
  // #region fetch

  async oldFetchDossierList({ commit }, payload) {
    // const queryString = new URLSearchParams(Object.entries(payload)).toString()
    await apiClient
      .post('dossier-list', payload)
      .then(async (response) => {
        await commit('setDossierList', response.data.dossiers)
        await commit(
          'dashboard/setCurrentTemplateColumnList',
          response.data.metadata,
          {
            root: true
          }
        )
        processSuccessCallback(payload)
      })
      .catch(async (reason) => {
        await console.error('fetchDossierList', reason)
      })
  },

  async fetchArchivedDossierList({ commit }, payload) {
    await apiClient
      .get(`dossier/?status=${payload.status}`)
      .then(async (response) => {
        await commit('setDossierList', response.data.dossiers)
        await commit('setDossierMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error('fetchArchivedDossierList', reason)
      })
  },

  fetchDossierList: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier`, {
          params: payload
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchDossier: ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  deleteDossier: ({ commit, dispatch }, id) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(`dossier/delete-dossier/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  changeDossierType: ({ commit, dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(`dossier/change-type/${payload.id}`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchDossierByID({ commit, dispatch }, id) {
    if (!id) {
      return
    }
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${id}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              'metadata/setCurrentGlobalForm',
              {
                funeral_home_id: response.data.dossier.funeral_home_id
              },
              { root: true }
            )
            commit('setInitialDossier', response.data.dossier)
            commit('setDossier', response.data.dossier)
            commit('setDossierMetadata', response.data.metadata)
            commit('auth/setErrors', base_errors, { root: true })
            if (!response.data.dossier.events) {
              commit('setDossierEventsKey')
            }
            if (!response.data.dossier.properties) {
              commit('setDossierProperties')
            }
            if (!response.data.dossier.items) {
              commit('setDossierItems', [])
            }
          }
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async fetchSupplierOrderByDossier({ commit }, id) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`dossier/supplier_order/${id}`)
      .then(async (response) => {
        await commit('invoicing/setInvoiceIndex', response.data.invoices, {
          root: true
        })
      })
      .catch(async (reason) => {
        await console.error('fetchSupplierOrderByDossier', reason)
      })
  },

  async fetchNewDossier({ commit }) {
    commit('setDossier', { deceased: {}, dossier_items: [] })
    commit('auth/setErrors', base_errors, { root: true })

    await commit('setDossier', {})
    await commit('setDossierMetadata', {})

    await apiClient
      .get('model/new?model=dossier')
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setDossier', response.data.dossier)
          await commit('setDossierMetadata', response.data.metadata)
          await commit('resetDossierConcernedPerson')
        }
      })
      .catch(async (reason) => {
        await console.error('fetchNewDossier', reason)
      })

    // await apiClient
    //   .get('model/new?model=dossier')
    //   .then(async (response) => {
    //     await commit('auth/setErrors', base_errors, { root: true })
    //     await commit('setDossier', {
    //       ...response.data.dossier,
    //       deceased: {},
    //       events: []
    //     })
    //     await commit('setDossierMetadata', response.data.metadata)
    //     await commit('replaceContact')
    //   })
    //   .catch(async (reason) => {
    //     await console.error('fetchNewDossier', reason)
    //   })
  },
  fetchContactDossierList({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`dossier-contacts/${payload.dossier_id}`, {
          params: payload.params
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async fetchTransfertsDossierList({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`dossier-transferts/${payload.dossier_id}`, {
          params: payload.params
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async fetchCeremoniesDossierList({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`dossier-ceremonies/${payload.dossier_id}`, {
          params: payload.params
        })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async fetchDossierActivities({ commit }, payload) {
    commit('setDossierActivities', [])
    await apiClient
      .get(`dossier/fetch-activities/${payload.dossier_id}`)
      .then(async (response) => {
        await commit('setDossierActivities', response.data.activities)
        await commit('setDossierMetadataActivities', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchDossierActivities', reason)
      })
  },
  fetchAllDocumentAttachedToDossier({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('dossier-documents/' + payload.dossier_id)
        .then((response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },


  fetchDocumentsList: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/document`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchInvoicesList: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/invoices`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchDossierItemsList: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/items`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchSettingsManagementDossier: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/management`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // #endregion

  // #region store

  async storeNewDossier({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .post('dossier', payload.dossier)
      .then(async (response) => {
        if (response.status === 201) {
          commit('setEditionMode', true)
          if (payload.type === 'normal') {
            await router.push({
              name: 'dossier_form_information_route_edit',
              params: { id: response.data.dossier }
            })
          } else {
            await router.push({
              name: 'dossier_light_form_information_route',
              params: { id: response.data.dossier }
            })
          }
        }
      })
      .catch((reason) => {
        console.error('storeNewDossier', reason)
      })
  },

  // #endregion

  // #region update

  updateDossierStatus({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`dossier/${payload.dossier_id}/status`, payload.form_data)
        .then((response) => {
          commit('auth/setErrors', {}, { root: true })
          commit('setDossier', response.data.dossier)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async updateDossierComplete({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(`dossier/${payload.dossier_id}/complete`, payload.form_data)
      .then((response) => {
        if (response.status === 200) {
          commit('auth/setErrors', {}, { root: true })
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        if (payload.errorCallback) {
          payload.errorCallback()
        }
      })
  },

  async updateDossier({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(`dossier/${payload.form_data.id}`, payload.form_data.dossier)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
          await commit('setDossier', response.data.dossier)
        }
      })
      .catch((reason) => {
        console.error('updateDossier', reason)
      })
  },
  async runAutomationDossier({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(`dossier/${payload.dossier_id}/automation/${payload.automation_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
          await commit('setDossier', response.data.dossier)
        }
      })
      .catch((reason) => {
        console.error('runAutomationDossier', reason)
      })
  },

  // #endregion

  // #region delete
  async deleteDossierById({ commit }, payload) {
    await apiClient
      .delete(`dossier/${payload.dossier_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
          await commit('setDossierList', response.data.dossiers)
        }
      })
      .catch((reason) => {
        console.error('deleteDossierById', reason)
      })
  },

  async undoDeleteDossierById({ commit }, payload) {
    await apiClient
      .get(`dossier/undo-delete/${payload.dossier_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
          await commit('setDossierList', response.data.dossiers)
        }
      })
      .catch((reason) => {
        console.error('undoDeleteDossierById', reason)
      })
  },

  // #endregion
}
