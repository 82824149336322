const TheItemIndexPage = () =>
  import('@/views/settings/invoicing/item/index/TheItemIndexPage')
const TheItemFormsTabComponent = () =>
  import('@/views/settings/invoicing/item/form/TheItemFormsTabComponent')
const TheItemInformationPage = () =>
  import(
    '@/views/settings/invoicing/item/form/tabs/information/TheItemInformationPage'
  )
const TheItemTranslationPage = () =>
  import(
    '@/views/settings/invoicing/item/form/tabs/translation/TheItemTranslationPage'
  )
const TheItemSpecificsPage = () =>
  import(
    '@/views/settings/invoicing/item/form/tabs/specifics/TheItemSpecificsPage'
  )
const TheItemAutomationPage = () =>
  import(
    '@/views/settings/invoicing/item/form/tabs/automation/TheItemAutomationPage'
  )
const TheItemActivityPage = () =>
  import('@/views/settings/invoicing/item/form/ItemTabForm/TheItemActivityPage')
const TheItemGalleryPage = () =>
  import('@/views/settings/invoicing/item/form/tabs/galery/TheItemGalleryPage')
const TheItemVariantPage = () =>
  import('@/views/settings/invoicing/item/form/tabs/variant/TheItemVariantPage')
const TheItemStockPage = () =>
  import('@/views/settings/invoicing/item/form/tabs/stock/TheItemStockPage')

export const settingsItemRoute = [
  {
    path: 'item',
    children: [
      {
        path: '',
        name: 'settings_list_item_route',
        component: TheItemIndexPage,
        meta: {
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_item_profile_route',
        props: true,
        component: TheItemFormsTabComponent,
        children: [
          {
            path: '',
            component: TheItemInformationPage,
            name: 'settings_edit_item_route'
          },
          {
            path: 'variant',
            component: TheItemVariantPage,
            props: true,
            name: 'settings_edit_item_variant_route'
          },
          {
            path: 'gallery',
            component: TheItemGalleryPage,
            props: true,
            name: 'settings_edit_item_gallery_route'
          },
          {
            path: 'translation',
            component: TheItemTranslationPage,
            props: true,
            name: 'settings_edit_item_translation_route'
          },
          {
            path: 'specifics',
            component: TheItemSpecificsPage,
            props: true,
            name: 'settings_edit_item_specifics_route'
          },
          {
            path: 'stock',
            component: TheItemStockPage,
            props: true,
            name: 'settings_edit_item_stock_route'
          },
          {
            path: 'automations',
            component: TheItemAutomationPage,
            props: true,
            name: 'settings_edit_item_automations_route'
          },
          {
            path: 'activities',
            component: TheItemActivityPage,
            props: true,
            name: 'settings_edit_item_activity_route'
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_item_title', active: false },
            { title: 'content_item_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_items_route',
        component: TheItemFormsTabComponent,
        children: [
          {
            path: '',
            component: TheItemInformationPage,
            name: 'settings_create_item_route'
          },
          {
            path: 'gallery',
            component: TheItemGalleryPage,
            props: true,
            name: 'settings_create_item_gallery_route'
          },
          {
            path: 'translation',
            component: TheItemTranslationPage,
            props: true,
            name: 'settings_create_item_translation_route'
          },
          {
            path: 'specifics',
            component: TheItemSpecificsPage,
            props: true,
            name: 'settings_create_item_specifics_route'
          },
          {
            path: 'stock',
            component: TheItemStockPage,
            props: true,
            name: 'settings_create_item_stock_route'
          },
          {
            path: 'automations',
            component: TheItemAutomationPage,
            props: true,
            name: 'settings_create_item_automations_route'
          }
        ],
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_item_new_title', active: true }
          ]
        }
      },
      {
        path: 'variante/new',
        name: 'settings_create_items_variante_route',
        component: TheItemFormsTabComponent,
        children: [
          {
            path: '',
            component: TheItemInformationPage,
            name: 'settings_create_item_variante_route'
          },
          {
            path: 'gallery',
            component: TheItemGalleryPage,
            props: true,
            name: 'settings_create_item_variante_gallery_route'
          },
          {
            path: 'translation',
            component: TheItemTranslationPage,
            props: true,
            name: 'settings_create_item_variante_translation_route'
          },
          {
            path: 'specifics',
            component: TheItemSpecificsPage,
            props: true,
            name: 'settings_create_item_variante_specifics_route'
          },
          {
            path: 'automations',
            component: TheItemAutomationPage,
            props: true,
            name: 'settings_create_item_variante_automations_route'
          }
        ],
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_item_new_title', active: true }
          ]
        }
      }
    ]
  }
]
