import { DocTypes } from '@/assets/enums'
import store from '@/store'
import DashboardTypes from '@/assets/enums/dashboard/DashboardTypes'
import TheInvoicingEdit from '@/views/invoicing/show/tabs/edit/TheInvoicingEdit.vue'
import TheAccountingExportPage from '@/views/invoicing/index/accountingExport/TheAccountingExportPage.vue'

const TheInvoicingIndexPage = () =>
  import('@/views/invoicing/index/TheInvoicingIndexPage')

const CheckDepositIndex = () => import('@/views/check/index/CheckDepositIndex')
const CheckDepositForm = () =>
  import('@/views/check/form/components/CheckDepositForm')
const CheckListByStatus = () =>
  import('@/views/check/index/checkByStatus/CheckListByStatus')
const InvoiceTabActivity = () =>
  import('@/views/invoicing/show/tabs/activity/TheInvoiceActivity')
const TheInvoicingLayout = () => import('@/views/invoicing/TheInvoicingLayout')
const CheckFormTabsComponents = () =>
  import('@/views/check/form/CheckFormTabsComponent')
const TheInvoicingShowLayout = () =>
  import('@/views/invoicing/show/TheInvoicingShowLayout')
const TheInvoicingWizard = () =>
  import('@/views/invoicing/form/TheInvoicingWizard')
const InvoicingPrintPage = () =>
  import('@/views/invoicing/show/print/InvoicingPrintPage')
const TheInvoicingShow = () =>
  import('@/views/invoicing/show/tabs/information/TheInvoicingShow')
const TheThirdPartyCostIndex = () =>
  import('@/views/invoicing/index/thirdPartyCost/TheThirdPartyCostIndex')

// export const invoicingRootPages = {
//   path: '/invoicing',
//   name: 'invoicing_page_route',
//   beforeEnter(_from, _to, next) {
//     next({ name: 'invoice_index_route' })
//   }
// }

export const invoicingPages = [
  {
    path: '/invoicing',
    name: 'invoicing_root_page',
    redirect: { name: 'invoice_index_route' },
    children: [
      {
        path: ''
      },
      {
        path: 'new',
        name: 'invoicing_wizard_route',
        component: TheInvoicingWizard,
        meta: { type: 'create', doc_type: null }
      },
      {
        path: 'invoice',
        component: TheInvoicingLayout,
        children: [
          {
            path: '',

            children: [
              {
                path: '',
                beforeEnter: (from, to, next) => {
                  let template = store.getters[
                    'metadata/getTemplateDashboardByType'
                  ](DashboardTypes.INVOICE)
                  if (Array.isArray(template) && template[0]) {
                    next({
                      name: 'invoice_index_route_id',
                      params: { id: template[0].id }
                    })
                    return
                  }
                  next()
                  return
                },
                name: 'invoice_index_route',
                component: TheInvoicingIndexPage,
                meta: {
                  doc_type: DocTypes.INVOICE,
                  dashboard_types: [DashboardTypes.INVOICE],
                  route_name: 'invoice_index_route_id'
                }
              },
              {
                path: ':id',
                name: 'invoice_index_route_id',
                component: TheInvoicingIndexPage,
                meta: {
                  doc_type: DocTypes.INVOICE,
                  dashboard_types: [DashboardTypes.INVOICE],
                  route_name: 'invoice_index_route_id'
                }
              },
              {
                path: 'new',
                component: TheInvoicingWizard,
                name: 'invoice_wizard_route',
                meta: { type: 'create', doc_type: DocTypes.INVOICE }
              }
            ]
          }
        ]
      },
      {
        path: 'purchase-order',
        component: TheInvoicingLayout,
        children: [
          {
            path: '',

            children: [
              {
                path: '',
                beforeEnter: (from, to, next) => {
                  let template = store.getters[
                    'metadata/getTemplateDashboardByType'
                  ](DashboardTypes.PURCHASE_ORDER)
                  if (Array.isArray(template) && template[0]) {
                    next({
                      name: 'purchase_order_index_route_id',
                      params: { id: template[0].id }
                    })
                    return
                  }
                  next()
                  return
                },
                name: 'purchase_order_index_route',
                component: TheInvoicingIndexPage,
                meta: {
                  doc_type: DocTypes.PURCHASE_ORDER,
                  dashboard_types: [DashboardTypes.PURCHASE_ORDER],
                  route_name: 'purchase_order_index_route_id'
                }
              },
              {
                path: ':id',
                name: 'purchase_order_index_route_id',
                component: TheInvoicingIndexPage,
                meta: {
                  doc_type: DocTypes.PURCHASE_ORDER,
                  dashboard_types: [DashboardTypes.PURCHASE_ORDER],
                  route_name: 'purchase_order_index_route_id'
                }
              },
              {
                path: 'new',
                component: TheInvoicingWizard,
                name: 'purchase_order_wizard_route',
                meta: { type: 'create', doc_type: DocTypes.PURCHASE_ORDER }
              }
            ]
          }
        ]
      },
      {
        path: 'quote',
        component: TheInvoicingLayout,
        children: [
          {
            path: '',

            children: [
              {
                path: '',
                beforeEnter: (from, to, next) => {
                  let template = store.getters[
                    'metadata/getTemplateDashboardByType'
                  ](DashboardTypes.QUOTE)
                  if (Array.isArray(template) && template[0]) {
                    next({
                      name: 'quote_index_route_id',
                      params: { id: template[0].id }
                    })
                    return
                  }
                  next()
                  return
                },
                name: 'quote_index_route',
                component: TheInvoicingIndexPage,
                meta: {
                  doc_type: DocTypes.QUOTE,
                  dashboard_types: [DashboardTypes.QUOTE],
                  route_name: 'quote_index_route_id'
                }
              },
              {
                path: ':id',
                name: 'quote_index_route_id',
                component: TheInvoicingIndexPage,
                meta: {
                  doc_type: DocTypes.QUOTE,
                  dashboard_types: [DashboardTypes.QUOTE],
                  route_name: 'quote_index_route_id'
                }
              },
              {
                path: 'new',
                component: TheInvoicingWizard,
                name: 'quote_wizard_route',
                meta: { type: 'create', doc_type: DocTypes.QUOTE }
              }
            ]
          }
        ]
      },
      {
        path: 'credit-note',
        component: TheInvoicingLayout,
        children: [
          {
            path: '',
            children: [
              {
                path: '',
                name: 'credit_note_index_route',
                component: TheInvoicingIndexPage,
                beforeEnter: (from, to, next) => {
                  let template = store.getters[
                    'metadata/getTemplateDashboardByType'
                  ](DashboardTypes.CREDIT_NOTE)
                  if (Array.isArray(template) && template[0]) {
                    next({
                      name: 'credit_note_index_route_id',
                      params: { id: template[0].id }
                    })
                    return
                  }
                  next()
                  return
                },
                meta: {
                  doc_type: DocTypes.CREDIT_NOTE,
                  dashboard_types: [DashboardTypes.CREDIT_NOTE],
                  route_name: 'credit_note_index_route_id'
                }
              },
              {
                path: ':id',
                name: 'credit_note_index_route_id',
                component: TheInvoicingIndexPage,
                meta: {
                  doc_type: DocTypes.CREDIT_NOTE,
                  dashboard_types: [DashboardTypes.CREDIT_NOTE],
                  route_name: 'credit_note_index_route_id'
                }
              },
              {
                path: 'new',
                component: TheInvoicingWizard,
                name: 'credit_note_wizard_route',
                meta: { type: 'create', doc_type: DocTypes.CREDIT_NOTE }
              }
            ]
          }
        ]
      },
      {
        path: 'third-party-cost',
        component: TheThirdPartyCostIndex,
        name: 'third_party_cost_index_route',
        meta: { doc_type: 'tpc' }
      },
      {
        path: 'export',
        component: TheAccountingExportPage,
        name: 'accounting_export_page_route'
      },
      {
        path: 'check-deposit',
        name: 'check_page_route',
        component: CheckFormTabsComponents,
        children: [
          {
            path: 'list',
            component: CheckDepositIndex,
            name: 'check_index_route'
          },
          {
            path: ':checkDepositId',
            children: [
              {
                path: '',
                component: CheckDepositForm,
                props: true,
                name: 'check_deposit_edit_route'
              },
              {
                path: 'print',
                component: CheckDepositForm,
                props: true,
                name: 'check_deposit_edit_route_2'
              }
            ]
          },
          {
            path: '',
            component: CheckDepositForm,
            props: true,
            name: 'check_deposit_create_route'
          },
          {
            path: 'checks',
            children: [
              {
                path: 'TBDEP',
                component: CheckListByStatus,
                name: 'check_tbdep_index_route',
                props: {
                  status: 'TBDEP'
                }
              },
              {
                path: 'DEP',
                component: CheckListByStatus,
                name: 'check_dep_index_route',
                props: {
                  status: 'DEP'
                }
              },
              {
                path: 'REF',
                component: CheckListByStatus,
                name: 'check_ref_index_route',
                props: {
                  status: 'REF'
                }
              }
            ]
          }
        ]
      },
      {
        path: ':invoicing_id',
        name: 'invoice_show_route',
        component: TheInvoicingShowLayout,
        redirect: { name: 'invoice_info_route' },
        props: true,
        children: [
          {
            path: '',
            component: TheInvoicingShow,
            name: 'invoice_info_route',
            props: true
          },
          {
            path: 'edit',
            component: TheInvoicingEdit,
            name: 'invoice_edit_route',
            props: true
          },
          {
            path: 'print',
            props: true,
            component: InvoicingPrintPage,
            name: 'invoice_print_route'
          },
          {
            path: 'activities',
            props: true,
            component: InvoiceTabActivity,
            name: 'invoice_activity_route'
          }
        ]
      }
    ]
  }
]

// export const invoicingSubPages = [
//   {
//     path: '/invoicing',
//     name: 'invoicing_index_route',
//     component: TheInvoicingLayout,
//     children: [
//       {
//         path: 'invoice',
//         component: TheInvoicingIndexPage,
//         name: 'invoice_index_route',
//         meta: { doc_type: DocTypes.INVOICE }
//       },
//       {
//         path: 'export',
//         component: TheInvoicingExportPage,
//         name: 'invoice_export_route'
//         // meta: {doc_type: 'export'}
//       },
//       {
//         path: 'invoice/new',
//         component: TheInvoicingWizard,
//         name: 'invoice_wizard_route',
//         meta: { type: 'create' }
//       },
//       {
//         path: 'purchase-order',
//         component: TheInvoicingIndexPage,
//         name: 'invoicing_purchase_order_index_route',
//         meta: { doc_type: DocTypes.PURCHASE_ORDER }
//       },
//       {
//         path: 'quote',
//         component: TheInvoicingIndexPage,
//         name: 'invoicing_quote_index_route',
//         meta: { doc_type: DocTypes.QUOTE }
//       },
//       {
//         path: 'credit-note',
//         component: TheInvoicingIndexPage,
//         name: 'invoicing_credit_note_index_route',
//         meta: { doc_type: DocTypes.CREDIT_NOTE }
//       },
//       {
//         path: 'third-party-cost',
//         component: TheThirdPartyCostIndex,
//         name: 'invoicing_third_party_cost_index_route',
//         meta: { doc_type: 'tpc' }
//       },
//   {
//     path: 'invoicing/:invoicing_id',
//     name: 'invoice_show_route',
//     props: true,
//     children: [
//       {
//         path: '',
//         component: TheInvoicingShow,
//         name: 'invoice_info_route'
//       },
//       {
//         path: 'print',
//         props: true,
//         component: InvoicingPrintPage,
//         name: 'invoice_print_route'
//       },
//       {
//         path: 'activities',
//         props: true,
//         component: InvoiceTabActivity,
//         name: 'invoice_activity_route'
//       }
//     ]
//   }
// ]
