<template>
  <div>
    <BaseAvatar
      :initials="user?.initials ?? '##'"
      :status-color="state_color"
      :blink-icon-enabled="blinkIconEnabled"
      :blink-icon="blinkIcon"
      :is-square="isSquare"
      :hide-status="hideStatus"
      :ring-color="ringColor"
      :border-color="borderColorComputed"
      :shadow-color="shadowColor"
      :size="size"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseAvatar from '@c/Avatar/BaseAvatar.vue'

export default {
  name: 'UserAvatar',
  components: { BaseAvatar },
  props: {
    userId: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: false,
      default: null
    },
    isSquare: {
      type: Boolean,
      required: false,
      default: false
    },
    hideStatus: {
      type: Boolean,
      required: false,
      default: false
    },
    blinkIconEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    blinkIcon: {
      type: Array,
      required: false,
      default: () => ['fal', 'times']
    },
    ringColor: {
      type: String,
      required: false,
      default: null
    },
    shadowColor: {
      type: String,
      required: false,
      default: null
    },
    disponibility: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: (value) =>
        ['extra-small', 'small', 'medium', 'large', 'extra-large'].indexOf(
          value
        ) !== -1
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'metadata/getAllUserMetadata'
    }),
    user() {
      return this.getUser(this.userId)
    },
    borderColorComputed() {
      if (this.disponibility) {
        switch (this.disponibility) {
          case 'pending':
            return 'border-yellow-400'
          case 'confirmed':
            return 'border-green-500'
          case 'refused':
            return 'border-red-500'
        }
      }
      return 'border-white'
    },
    state_color() {
      if (!this.user) {
        return 'bg-red-700 text-white'
      }
      if (!this.user.online) {
        return 'bg-red-700 text-white'
      }
      switch (this.user.status) {
        case 'available':
          return 'bg-green-400 text-slate-800'
        case 'unavailable':
        case 'busy':
          return 'bg-yellow-500 text-slate-800'
        case 'offline':
          return 'bg-red-700 text-white'
        default:
          return 'bg-gray-300  text-slate-800'
      }
    }
  }
}
</script>
