<template>
  <div class="relative" :class="label ? 'mt-2' : ''">
    <label
      v-if="label"
      :for="name"
      class="absolute -top-2 left-2 z-10 -mt-px inline-block rounded-md bg-white/75 px-1 text-xs font-medium text-gray-900 capitalize-first dark:bg-slate-500 dark:bg-white/100"
    >
      {{ label }}
      <span v-if="label && required" class="text-red-600">&nbsp;*</span>
    </label>
    <div
      :id="`walkman-${name}`"
      class="divide-x overflow-hidden rounded-md border border-slate-300 shadow-sm dark:border dark:border-white dark:bg-slate-700 dark:text-white"
      :class="[wFull ? 'flex flex-row' : 'inline-flex']"
      role="group"
    >
      <button
        v-for="(option, index) in options"
        :key="index"
        type="button"
        :disabled="!editionMode"
        :class="[
          option[trackBy] === selectedValues
            ? 'text-white '
            : 'bg-white text-gray-900 focus:bg-primary-100 active:bg-primary-100 enabled:hover:bg-primary-100',
          option.bgClass && option[trackBy] === selectedValues
            ? option.bgClass
            : 'bg-primary-500 focus:bg-primary-400 active:bg-primary-400 enabled:hover:bg-primary-500 enabled:hover:text-contrast-primary',
          buttonClass,
          wFull ? 'flex-1' : ''
        ]"
        class="text-sm font-medium outline-0 disabled:cursor-not-allowed"
        @click="() => (selectedValues = option[trackBy])"
      >
        <span v-if="isIcon">
          <FontAwesomeIcon :icon="['fas', option['icon']]" />
        </span>
        <span v-else class="flex items-center justify-center gap-2">
          <span class="inline-block truncate capitalize-first">{{
            option[attributeLabel]
          }}</span>
          <span
            v-if="option['badge_value']"
            class="rounded-md bg-secondary px-1 py-0.5 text-xs text-contrast-secondary"
          >
            {{ option['badge_value'] }}
          </span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'WalkmanButton',
  components: { FontAwesomeIcon },
  props: {
    modelValue: {
      type: [String, Boolean],
      default: null,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    trackBy: {
      type: String,
      required: false,
      default: 'value'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'label'
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedBackground: {
      type: String,
      required: false,
      default: 'bg-gray-200'
    },
    size: {
      type: String,
      default: '',
      required: false
    },
    wFull: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const selectedValues = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value)
        emit('change', value)
      }
    })

    return { selectedValues }
  },
  computed: {
    buttonClass() {
      switch (this.size) {
        case 'extra-small':
          return 'px-2 py-1 gap-1 text-xs'
        case 'small':
          return 'px-3 py-1.5'
        case 'large':
          return 'px-5 py-3'
        case 'medium':
        default:
          return 'px-4 py-2'
      }
    }
  }
}
</script>
