<template>
  <div :class="isElementModal ? 'grid' : 'flex w-full flex-col'">
    <div :class="isElementModal ? 'col-span-1' : 'flex flex-col gap-2 p-2'">
      <FilterLineComponent
        v-for="(filter, index) in filters"
        :key="index"
        v-model:filter="filters[index]"
        :index="index"
        :filters-available="filtersAvailable"
        :dashboard-type="dashboardType"
        :is-element-modal="isElementModal"
        @delete-line="removeFilterLine(filter, index)"
      />
    </div>
    <div
      class="flex flex-row place-content-between justify-between rounded-b-md bg-gray-100 px-3 py-2 dark:bg-slate-800 dark:text-gray-300"
    >
      <button
        class="flex flex-row items-center gap-2 rounded-md bg-blue-500 px-3 py-1 text-white"
        @click="addFilterLine"
      >
        <FontAwesomeIcon :icon="['fas', 'plus']"></FontAwesomeIcon>
        <span class="font-medium">{{ $t('global.add_line') }}</span>
      </button>
      <button
        v-if="!isElementModal"
        class="flex flex-row items-center gap-2 rounded-md bg-blue-500 px-3 py-1 text-white"
        @click="applyFilter"
      >
        <FontAwesomeIcon :icon="['fas', 'check']"></FontAwesomeIcon>
        <span class="font-medium">{{ $t('global.filter') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import FilterLineComponent from '@c/Dashboard/Components/FilterLineComponent.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

let default_filter = {
  whereType: 'where',
  column: null,
  values: null,
  condition: null
}

export default {
  name: 'FilterLinesComponent',
  components: { FontAwesomeIcon, FilterLineComponent },
  props: {
    filters: {
      type: Array,
      required: true
    },
    filtersAvailable: {
      type: Array,
      required: true
    },
    dashboardType: {
      type: String,
      required: false,
      default: ''
    },
    isElementModal: {
      type: Boolean,
      required: false,
      default: false
    },
    requireSaveDashboard: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: [
    'update:filters',
    'close-popover',
    'remove:item:line',
    'update:require-save-dashboard'
  ],
  computed: {
    localFilter: {
      get() {
        return this.filters
      },
      set(value) {
        this.$emit('update:filters', value)
      }
    },
    localRequireSaveDashboard: {
      get() {
        return this.requireSaveDashboard
      },
      set(value) {
        this.$emit('update:require-save-dashboard', value)
      }
    }
  },
  methods: {
    addFilterLine() {
      this.$emit(
        'update:filters',
        Array.isArray(this.filters)
          ? [...this.filters, ...[{ ...default_filter }]]
          : [{ ...default_filter }]
      )

      this.makeRequireSaveDashboard()
    },
    removeFilterLine(filter, index) {
      this.filters.splice(index, 1)
      this.$emit('remove:item:line', this.filters)

      this.makeRequireSaveDashboard()
    },
    applyFilter() {
      this.$emit('close-popover')

      this.makeRequireSaveDashboard()
    },
    makeRequireSaveDashboard() {
      if (!this.localRequireSaveDashboard) {
        this.localRequireSaveDashboard = true
      }
    }
  }
}
</script>
