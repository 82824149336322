/* eslint-disable no-unused-vars */
import apiClient from '@u/apiClient'
import {helper} from '@u/helper'

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

function initDocumentForm(document) {
  if (document.form) {
    document.form.forEach((it, key) => {
      // Disable the rewrite form name
      it.saved = true
      it.valid = true

      if (it.options) {
        it.options.forEach((opt) => {
          // Disable the rewrite form name
          opt.saved = true
          opt.valid = true
        })
      }
    })
  }

  return document
}

const base_errors = {}

export default {
  // region fetch

  fetchTenantDocumentsList: ({commit}) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get('document')
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  fetchWikiSharedDocumentsList: ({commit}) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get('wiki-shared-docs')
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  fetchDocumentById: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`document/${payload.document_id}`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchDocumentEditor: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`editor/${payload.document_type}/${payload.document_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchNewDocumentEditor: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`editor/${payload.document_type}`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchDocumentPreviewV3: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`editor/${payload.document_type}/${payload.document_id}/${payload.dossier_id}/preview-v3`,
          {
            stringContent: typeof payload.stringContent === 'boolean' ? payload.stringContent : true,
            ...payload.data,
          },
          {
            responseType: 'blob',
            headers: {
              'Accept': 'application/pdf'
            },
            params: payload.params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  copyLocalToLibrary: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `document/${payload.document_id}/copy-local-to-library`,
          payload.form
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  copyLibraryToLocal: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`document/${payload.document_id}/copy-library-to-local`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  storeDocumentFromDocumentEditor: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('document', JSON.stringify(payload.params))

      if (payload.model_file) {
        helper.toFormData({
          model_file: payload.model_file
        }, null, formData)
      }

      apiClient
        .post(`/editor/${payload.document_type}`, formData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  updateDocumentFromDocumentEditor: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `/editor/${payload.document_type}/${payload.document_id}`,
          {
            document: payload.params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async fetchDocument({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient
      .get('document/' + payload.document_id)
      .then(async (response) => {
        await commit('setDocument', initDocumentForm(response.data.document))
      })
      .catch(async (reason) => {
        console.error('fetchDocument', reason)
      })
  },
  fetchDocumentDossier: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(
          `dossier/${payload.dossier_id}/${payload.document_type}/${payload.document_id}`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async fetchNewDocument({commit}) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient
      .get('model/new?model=document')
      .then(async (response) => {
        await commit('setDocument', initDocumentForm(response.data.document))
      })
      .catch(async (reason) => {
        console.error('fetchDocument', reason)
      })
  },

  async fetchDocumentActivitiesByDocumentID({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient
      .get(`document/fetch-activities/${payload.document_id}`)
      .then(async (response) => {
        await commit('setDocumentActivities', response.data.activities)
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  },

  async fetchDocumentHeadersList({commit}) {
    await apiClient
      .get('document-headers')
      .then(async (response) => {
        await commit('setDocumentsHeaders', response.data.headers)
      })
      .catch(async (reason) => {
        await console.error('fetchContactDossierList', reason)
      })
  },
  // endregion

  // region store
  async storeDocument({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    let formData = new FormData()

    formData.append('data', JSON.stringify(payload.document))

    if (payload.document.template) {
      formData.append('template', payload.document.template)
    }

    await apiClient
      .post(`document`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(async (response) => {
        if (response.status === 200) {
          // await commit('setDocument', response.data.document)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('storeDocument', reason)
      })
  },

  addWikiSharedDocToDocumentList: ({commit}, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(`add-wiki-shared-document`, payload)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  async updateDocument({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    let formData = new FormData()
    formData.append('data', JSON.stringify(payload.document))

    if (payload.document.template) {
      formData.append('template', payload.document.template)
    }
    formData.append('_method', 'PUT')
    await apiClient
      .post(`document/${payload.document_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setDocument', initDocumentForm(response.data.document))
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('updateDocument', reason)
      })
  },
  // endregion

  // region attachment
  async saveDocumentAsAttachment({commit}, payload) {
    await apiClient
      .get(`${payload.url}`)
      .then(async (response) => {
        if (payload.successCallback) {
          payload.successCallback()
        }
      })
      .catch(async (reason) => {
        console.error('saveDocumentAsAttachment', reason)
      })
  },
  // endregion

  // region delete
  deleteDocumentById: ({commit}, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(`document/${payload.id}`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  deleteWikiSharedDocumentById: ({commit}, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(`wiki-shared-docs/${payload.id}`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region undoDelete
  undoDeleteDocumentById: ({commit}, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`document/undo-delete/${payload.id}`)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  undoDeleteWikiSharedDocById: ({commit}, payload) => {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`wiki-shared-docs/undo-delete/${payload.id}`)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  processDeletePreviewDocumentEditorAction: async ({commit}, payload) => {
    const {
      doc_type,
      doc_id,
      media_id,
    } = payload

    return apiClient
      .delete(`/editor/document/preview/delete/${doc_type}/${doc_id}/${media_id}`)
      .then((res) => {
        if (res?.data) {
          return res.data
        }

        return {success: false}
      })
  },

  processReplacePreviewDocumentEditorAction: async ({commit}, payload) => {
    const {
      doc_type,
      doc_id,
      media_id,
      file,
    } = payload

    const data = new FormData()

    data.append('new_file', file)

    return apiClient
      .post(`/editor/document/preview/replace/${doc_type}/${doc_id}/${media_id}`, data)
      .then((res) => {
        if (res?.data) {
          return res.data
        }

        return {success: false}
      })
  },
  // endregion
}
