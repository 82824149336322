<template>
  <Popover class="z-100 focus:border-transparent">
    <Float placement="bottom-end" portal :offset="10">
      <PopoverButton
        class="group inline-flex items-center justify-between gap-3 rounded-md px-3 py-2 font-medium text-gray-600 hover:text-opacity-100"
      >
        <FontAwesomeIcon
          :icon="['fas', 'filter']"
          size="lg"
          class="-mr-1 focus:border-transparent"
        />
      </PopoverButton>

      <PopoverPanel
        v-slot="{ close }"
        class="right-0 z-100 w-screen max-w-xl transform px-4 sm:px-0"
      >
        <div
          class="flex flex-col gap-4 rounded-lg bg-white p-3 shadow-xl ring-1 ring-black ring-opacity-5"
        >
          <SelectComponent
            v-model="filter.tri"
            name="tri"
            :label="$t('global.tri')"
            :values="[
              { id: 'name', name: $t('attributes.name') },
              { id: 'price', name: $t('attributes.price') }
            ]"
            display-key="name"
            edition-mode
            required
          />
          <SelectComponent
            v-model="filter.type"
            name="type"
            :label="$t('attributes.type')"
            :values="[
              { id: 'asc', name: $t('global.asc') },
              { id: 'desc', name: $t('global.desc') }
            ]"
            display-key="name"
            edition-mode
            required
          />
          <div class="flex justify-end">
            <BaseButton
              :title="$t('button.close')"
              size="small"
              @click="close"
            />
          </div>
        </div>
      </PopoverPanel>
    </Float>
  </Popover>
</template>
<script>
import { mapGetters } from 'vuex'
import { PopoverButton, PopoverPanel, Popover } from '@headlessui/vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import { Float } from '@headlessui-float/vue'

export default {
  name: 'FilterCatalogPanel',
  components: {
    Float,
    BaseButton,
    Popover,
    PopoverButton,
    FontAwesomeIcon,
    SelectComponent,
    PopoverPanel
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  emits: ['sort-items', 'update:filter'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      metadata: 'invoicing/getCheckMetadata'
    }),
    localFilter: {
      get() {
        return this.filter
      },
      set(value) {
        this.$emit('update:filter', value)
      }
    }
  }
}
</script>
