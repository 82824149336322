<template>
  <div
    v-if="localSelectedInvoiceable"
    class="flex min-h-0 flex-1 flex-col rounded border p-3 pt-2"
  >
    <div class="flex flex-row">
      <div class="flex flex-1 flex-col">
        <h3 class="flex-1 text-base font-bold leading-tight text-gray-900">
          {{ localSelectedInvoiceable.full_name }}
        </h3>
        <div class="flex flex-row gap-2">
          <div
            v-if="localSelectedInvoiceable.relationship"
            class="rounded-md bg-primary px-2 py-0.5 text-xs text-contrast-primary capitalize-first"
          >
            {{
              relationshipInvoiceableLabel &&
              typeof relationshipInvoiceableLabel === 'object'
                ? relationshipInvoiceableLabel.name
                : relationshipInvoiceableLabel
            }}
          </div>
          <div
            v-if="localSelectedInvoiceable.is_purchaser"
            class="rounded-md bg-secondary px-2 py-0.5 text-xs text-contrast-secondary capitalize-first"
          >
            {{ $t('enums.contact.contactDossierTypes.purchaser') }}
          </div>
          <div
            v-if="localSelectedInvoiceable.is_handler"
            class="rounded-md bg-secondary px-2 py-0.5 text-xs text-contrast-secondary capitalize-first"
          >
            {{ $t('enums.contact.contactDossierTypes.handler') }}
          </div>
          <div
            v-if="localSelectedInvoiceable.is_co_handler"
            class="rounded-md bg-secondary px-2 py-0.5 text-xs text-contrast-secondary capitalize-first"
          >
            {{ $t('enums.contact.contactDossierTypes.co_handler') }}
          </div>
        </div>
      </div>
      <span class="shrink-0">
        <FontAwesomeIcon
          :icon="['fal', 'times']"
          @click="localSelectedInvoiceable = null"
        />
      </span>
    </div>
    <div class="text-xs">
      <p>
        {{ localSelectedInvoiceable.street }}<br />
        {{ localSelectedInvoiceable.city }}
      </p>
      <p
        v-if="
          localSelectedInvoiceable.phone_field &&
          localSelectedInvoiceable.phone_field.length
        "
      >
        {{ localSelectedInvoiceable.phone_field }}
      </p>
      <p
        v-if="
          localSelectedInvoiceable.mobile_field &&
          localSelectedInvoiceable.mobile_field.length
        "
      >
        {{ localSelectedInvoiceable.mobile_field }}
      </p>
    </div>
  </div>
  <div v-else class="flex min-h-0 flex-1 flex-col gap-3">
    <div>
      <WalkmanButton
        v-model="contactType"
        :options="contactTypeOptions"
        size="small"
      />
    </div>
    <template
      v-if="
        dossierId && contactType === 'dossier' && Array.isArray(dossierContacts)
      "
    >
      <div class="grid grid-cols-3 gap-3">
        <div v-if="dossierContacts.length === 0" class="text-lg font-bold">
          {{ $t('global.is_empty_list') }}
        </div>
        <div
          v-for="contact in dossierContacts"
          v-else
          :key="contact.id"
          class="flex flex-col rounded border border-slate-300 p-2 pt-1.5 dark:border dark:border-white dark:bg-slate-700"
          :class="contact.is_deceased ? 'bg-gray-100 ' : ''"
        >
          <div class="flex shrink-0 flex-row items-start">
            <div class="flex flex-1 flex-col">
              <h2 class="text-base font-bold">{{ contact.full_name }}</h2>
              <div class="flex flex-row gap-2">
                <div
                  v-if="contact.relationship"
                  class="rounded-md bg-primary px-2 py-0.5 text-xs text-contrast-primary capitalize-first"
                >
                  {{ relationshipContactLabel(contact.relationship) }}
                </div>
                <div
                  v-if="contact.is_purchaser"
                  class="rounded-md bg-secondary px-2 py-0.5 text-xs text-contrast-secondary capitalize-first"
                >
                  {{ $t('enums.contact.contactDossierTypes.purchaser') }}
                </div>
                <div
                  v-if="contact.is_handler"
                  class="rounded-md bg-secondary px-2 py-0.5 text-xs text-contrast-secondary capitalize-first"
                >
                  {{ $t('enums.contact.contactDossierTypes.handler') }}
                </div>
                <div
                  v-if="contact.is_co_handler"
                  class="rounded-md bg-secondary px-2 py-0.5 text-xs text-contrast-secondary capitalize-first"
                >
                  {{ $t('enums.contact.contactDossierTypes.co_handler') }}
                </div>
              </div>
            </div>
            <div class="shrink-0">
              <BaseButton
                size="extra-small"
                :title="$t('button.select')"
                :color="contact.is_deceased ? 'secondary' : 'primary'"
                :disabled="contact.is_deceased"
                @click="localSelectedInvoiceable = contact"
              />
            </div>
          </div>
          <div class="text-xs">
            <p>
              {{ contact.street }}<br />
              {{ contact.city }}
            </p>
            <p v-if="contact.phone_field && contact.phone_field.length">
              {{ contact.phone_field }}
            </p>
            <p v-if="contact.mobile_field && contact.mobile_field.length">
              {{ contact.mobile_field }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="contactType === 'all'">
      <template v-if="popup">
        <div>
          <BaseButton
            :title="$t('global.select_contact')"
            @click="modalOpened = true"
          />
        </div>
        <BaseCenteredModal
          v-model:is-modal-open="modalOpened"
          large
          :title="$t('global.select_contact')"
        >
          <template #body>
            <InvoiceableSelectorForm
              v-model:selected-invoiceable="localSelectedInvoiceable"
            />
          </template>
        </BaseCenteredModal>
      </template>
      <template v-else>
        <InvoiceableSelectorForm
          v-model:selected-invoiceable="localSelectedInvoiceable"
        />
      </template>
    </template>
    <template v-else-if="contactType === 'new'">
      <div
        class="flex w-full flex-col rounded border border-slate-300 p-4 pt-3"
      >
        <div>
          <InvoiceableForm
            v-model:contact="newContact"
            :suggest-link-to-dossier="dossierId"
            @form:is:valid="(v) => (canAddContact = v)"
          />
        </div>
        <div>
          <BaseButton
            :title="$t('button.save')"
            @click="useNewContact"
            :disabled="!canAddContact"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import InvoiceableSelectorForm from '@c/Invoiceable/component/InvoiceableSelectorForm.vue'
import WalkmanButton from '@c/WalkmanButton.vue'
import InvoiceableForm from '@c/Invoiceable/component/InvoiceableForm.vue'
import { InvoiceableType } from '@/assets/enums'

export default defineComponent({
  name: 'InvoiceableSelector',
  components: {
    InvoiceableForm,
    WalkmanButton,
    InvoiceableSelectorForm,
    BaseButton,
    BaseCenteredModal,
    FontAwesomeIcon
  },
  props: {
    selectedInvoiceable: {
      type: Object,
      default: null
    },
    dossierId: {
      type: String,
      default: null
    },
    popup: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:selectedInvoiceable'],
  data() {
    return {
      canAddContact: false,
      newContact: {
        relationship: null,
        contact_role: null,
        id: null,
        type: null,
        is_company: false,
        first_name: '',
        last_name: '',
        company_name: '',
        name: '',
        civility: '',
        street: '',
        extra_street: '',
        city: '',
        zip: '',
        city_id: '',
        country: '',
        tva: '',
        language: this.$i18n.locale,
        email: '',
        mobile_country: '',
        mobile_field: '',
        phone_country: '',
        phone_field: ''
      },
      modalOpened: false,
      contactType: 'dossier',
      dossierContacts: null
    }
  },
  computed: {
    ...mapGetters({
      default_country: 'metadata/getDefaultCountry',
      getRelationshipMetadata: 'metadata/getRelationshipMetadata'
    }),
    relationshipInvoiceableLabel() {
      if (this.localSelectedInvoiceable.relationship) {
        return this.getRelationshipMetadata(
          this.localSelectedInvoiceable.relationship
        )
      }
    },
    localSelectedInvoiceable: {
      get() {
        return this.selectedInvoiceable
      },
      set(value) {
        this.$emit('update:selectedInvoiceable', value)
      }
    },
    contactTypeOptions() {
      let options = [
        { value: 'all', label: this.$t('global.search_in_funeral_manager') },
        { value: 'new', label: this.$t('button.add_wiki_contact') }
      ]
      if (this.dossierId) {
        options = [
          { value: 'dossier', label: this.$t('global.dossier') },
          ...options
        ]
      }
      return options
    }
  },
  watch: {
    dossierId(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.contactType = 'dossier'
        } else {
          this.contactType = 'all'
        }
        this.loadContactDossier()
      }
    }
  },
  mounted() {
    if (this.dossierId) {
      this.contactType = 'dossier'
    } else {
      this.contactType = 'all'
    }
    this.loadContactDossier()
    this.newContact.phone_country = this.default_country
    this.newContact.mobile_country = this.default_country
    this.newContact.country = this.default_country
  },
  methods: {
    ...mapActions({
      processFetchContactDossierAction: 'dossier/fetchContactDossierList',
      processCheckContactAction: 'invoicing/checkContact'
    }),
    relationshipContactLabel(relationship) {
      if (relationship) {
        return this.getRelationshipMetadata(relationship)?.name
      }
    },
    loadContactDossier() {
      if (this.dossierId) {
        this.processFetchContactDossierAction({
          dossier_id: this.dossierId,
          params: {
            as_invoicable: true,
            with_concerned: true,
            with_deceased: false
          }
        }).then(
          (response) =>
            (this.dossierContacts = response.contacts.map((contact) => {
              return {
                ...contact,
                type: InvoiceableType.PERSON
              }
            }))
        )
      }
    },
    useNewContact() {
      this.processCheckContactAction(this.newContact).then((response) => {
        this.$emit('update:selectedInvoiceable', this.newContact)
      })
    }
  }
})
</script>
