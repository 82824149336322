<template>
  <div
    :key="modelValue"
    class="mt-2 flex w-full items-center justify-between rounded-md border border-slate-300 px-3 py-1.5 shadow-sm"
  >
    <div v-if="!onlySwitch" class="flex flex-row items-center">
      <FontAwesomeIcon
        :icon="modelValue ? [iconType, 'check-circle'] : [iconType, iconFalse]"
        :class="[modelValue ? 'text-green-500 ' : 'text-red-500', cClassIcon]"
      />

      <div class="flex flex-col">
        <div class="capitalize-first" :class="cClassLabel">
          {{ label }}
        </div>
        <p v-if="description" class="text-xs text-gray-500">
          {{ description }}
        </p>
      </div>
    </div>
    <div>
      <template v-if="editionMode">
        <BaseSwitch
          v-model="modelValue"
          :name="name"
          :disabled="disabled"
          @change="updateInput"
        />
      </template>
    </div>
  </div>
  <div
    v-for="(error, index) in errors"
    :key="index"
    class="form-help text-red-600"
  >
    {{ $t(error, { attribute: $t('attributes.' + name) }) }}
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseSwitch from '@c/addf-package/components/BaseSwitch/BaseSwitch.vue'

export default {
  name: 'BaseShowEditSwitch',
  components: { BaseSwitch, FontAwesomeIcon },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    onlySwitch: {
      type: Boolean,
      required: false,
      default: false
    },
    editionMode: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    classLabel: {
      type: String,
      required: false,
      default: ''
    },
    classIcon: {
      type: String,
      required: false,
      default: ''
    },
    iconFalse: {
      type: String,
      required: false,
      default: 'minus-circle'
    },
    iconType: {
      type: String,
      required: false,
      default: 'fal'
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: Array,
      require: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    cClassLabel() {
      return this.classLabel === ''
        ? 'text-sm font-medium text-gray-900'
        : this.classLabel
    },
    cClassIcon() {
      return this.classIcon === '' ? 'mt-0.5 mr-2' : this.classIcon
    }
  },
  methods: {
    updateInput(payload) {
      this.$emit('update:modelValue', payload.value)
      this.$emit('change', payload.value)
    }
  }
}
</script>
