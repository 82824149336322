<template>
  <div class="flex flex-col divide-y">
    <div class="flex flex-col gap-1 pb-3">
      <div v-if="invoice.dossier" class="flex flex-col gap-1">
        <div>
          <div
            class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
          >
            {{ $t('global.dossier') }}
          </div>
          <button
            class="text-left font-medium leading-tight text-primary-600 hover:underline"
            @click="
              confirmLink({
                name: 'dossier_edit_route_base',
                params: {
                  id: invoice.dossier.id
                }
              })
            "
          >
            {{ invoice.dossier?.reference }}
          </button>
        </div>
        <div v-if="invoice.dossier && invoice.dossier.concernedPerson">
          <div
            class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
          >
            {{ $t('global.concerned_person') }}
          </div>
          <button
            class="text-left font-medium leading-tight text-primary-600 hover:underline"
            @click="
              confirmLink({
                name: 'dossier_edit_route_base',
                params: {
                  id: invoice.dossier.id
                }
              })
            "
          >
            {{ invoice.dossier.concernedPerson.full_name }}
          </button>
        </div>
      </div>
      <div v-else class="flex flex-col gap-1">
        <div v-if="!lockActions">
          <div
            class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
          >
            {{ $t('global.dossier') }}
          </div>
          <BaseButton
            :title="$t('button.link_to_dossier')"
            size="extra-small"
            @click="() => (linkToDossierModalOpen = true)"
          />
        </div>
      </div>
      <template v-if="validated">
        <div>
          <div
            class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
          >
            {{ $t('attributes.reference') }}
          </div>
          <div class="font-medium leading-tight text-gray-900">
            {{ invoice.reference }}
          </div>
        </div>
        <div>
          <div
            class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
          >
            {{ $t('attributes.date') }}
          </div>
          <div class="font-medium leading-tight text-gray-900">
            {{ $h.formatDate(invoice.emission_date, 'DD-MM-YYYY') }}
          </div>
        </div>
        <div>
          <div
            class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
          >
            {{ $t('attributes.due_date') }}
          </div>
          <div class="font-medium leading-tight text-gray-900">
            {{ $h.formatDate(invoice.due_date, 'DD-MM-YYYY') }}
          </div>
        </div>
        <div v-if="$can('send_invoice_to_accounting') && invoice.company.chift">
          <div v-if="invoice.chift_id" class="bg-emerald-500 px-1 text-white">
            {{ $t('global.sent_to_accounting') }}
          </div>
          <template v-else>
            <BaseButton
              v-if="
                invoice.account_diary.settings.chift.send_options.type ===
                'manually'
              "
              theme-color="bg-secondary text-contrast-secondary"
              :title="$t('button.send_to_accounting')"
              icon="paper-plane"
              class="w-full"
              size="extra-small"
              @click="sendToAccounting"
            />
            <div v-if="invoice.chift_id">
              {{ $t('global.pending_accounting') }}
            </div>
          </template>
        </div>
      </template>
    </div>
    <div v-if="invoice.invoiceable_nomalized && !lockActions" class="py-3">
      <InvoiceableSelector
        v-model:invoiceable="invoice.invoiceable_nomalized"
        v-model:invoiceable-id="invoice.invoiceable_id"
        v-model:invoiceable-type="invoice.invoiceable_type"
        :invoice="invoice"
        @invoiceable-changed="updateInvoice"
      />
    </div>
    <div class="flex flex-col gap-1 py-3">
      <div>
        <div class="truncate leading-tight text-gray-500 capitalize-first">
          {{ $t('attributes.total_tvac') }}
        </div>
        <div class="text-2xl font-bold leading-tight text-gray-900">
          {{ $h.formatCurrency(invoice.total_tvac) }}
        </div>
      </div>
      <div v-if="hasRest">
        <div class="truncate leading-tight text-gray-500 capitalize-first">
          {{ $t('attributes.outstanding_balance') }}
        </div>
        <div class="text-2xl font-bold leading-tight text-gray-900">
          {{ $h.formatCurrency(getRest) }}
        </div>
      </div>
      <div>
        <div
          class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
        >
          {{ $t('attributes.total_htva') }}
        </div>
        <div class="font-medium leading-tight text-gray-900">
          {{ $h.formatCurrency(invoice.total_htva) }}
        </div>
      </div>
      <div>
        <div
          class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
        >
          {{ $t('attributes.total_hors_debours') }}
        </div>
        <div class="font-medium leading-tight text-gray-900">
          {{ $h.formatCurrency(invoice.total_hors_debours) }}
        </div>
      </div>
      <div v-if="totalPaid > 0">
        <div
          class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
        >
          {{ $t('invoicing.received_payments') }}
        </div>
        <div class="font-medium leading-tight text-gray-900">
          {{ $h.formatCurrency(totalPaid) }}
        </div>
      </div>
      <div v-if="awaitingPaymentAmount > 0">
        <div
          class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
        >
          {{ $t('invoicing.awaiting_payments') }}
        </div>
        <div class="font-medium leading-tight text-gray-900">
          {{ $h.formatCurrency(awaitingPaymentAmount) }}
        </div>
      </div>
    </div>
    <div
      v-if="onlineMembersOtherThanMe?.length"
      class="flex flex-col gap-1 py-2"
    >
      <div
        class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
      >
        {{ $t('global.currently_on_this_page') }}
      </div>
      <div class="flex-row flex-wrap -space-x-1">
        <template v-for="member in onlineMembersOtherThanMe" :key="member.id">
          <AvatarComponent
            :user_id="member.id"
            :size="8"
            :display-status="false"
            class="transform transition duration-200 hover:z-50 hover:scale-125"
          />
        </template>
      </div>
    </div>
    <div v-if="invoice.from_invoicing?.length" class="flex flex-col gap-1 py-3">
      <h4
        class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
      >
        {{ $t('invoicing.created_from') }}
      </h4>
      <template v-for="from in invoice.from_invoicing" :key="from.id">
        <button
          class="text-left font-medium leading-tight text-primary-600 hover:underline"
          @click="
            confirmLink({
              name: 'invoice_show_route',
              params: {
                invoicing_id: from.id
              }
            })
          "
        >
          {{ $t('enums.invoicing.docTypes.' + from.doc_type) }}
          {{ from.reference }}
        </button>
      </template>
    </div>
    <div v-if="invoice.to_invoicing?.length" class="flex flex-col gap-1 pt-3">
      <h4
        class="truncate text-xs font-medium leading-tight text-gray-500 capitalize-first"
      >
        {{ $t('invoicing.converted_into') }}
      </h4>
      <template v-for="to in invoice.to_invoicing" :key="to.id">
        <button
          class="text-left font-medium leading-tight text-primary-600 hover:underline"
          @click="
            confirmLink({
              name: 'invoice_show_route',
              params: {
                invoicing_id: to.id
              }
            })
          "
        >
          {{ $t('enums.invoicing.docTypes.' + to.doc_type) }}
          {{ to.reference }}
        </button>
      </template>
    </div>
    <ConfirmModal ref="invoicingShowInformationConfim" />
    <SearchDossierModal
      v-model:opened="linkToDossierModalOpen"
      @selected-dossier="updateInvoiceDossier"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InvoiceableSelector from '@/views/invoicing/show/tabs/information/components/information/components/InvoiceableSelector.vue'
import ConfirmModal from '@c/addf-package/components/modal/ConfirmModal.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import SearchDossierModal from '@ui/modal/dossier/SearchDossierModal.vue'
import AvatarComponent from '@c/AvatarComponent.vue'

export default {
  name: 'InvoicingShowInformation',
  components: {
    AvatarComponent,
    SearchDossierModal,
    BaseButton,
    ConfirmModal,
    InvoiceableSelector
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    lockActions: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      linkToDossierModalOpen: false,
      linkToDossierId: null
    }
  },

  computed: {
    ...mapGetters({
      online_members: 'invoicing/getOnlineMembers',
      user: 'auth/getUser'
    }),
    onlineMembersOtherThanMe() {
      return this.online_members.filter(
        (online_member) => online_member.id !== this.user.id
      )
    },
    validated() {
      return this.invoice.reference && this.invoice.reference.length
    },
    totalPaid() {
      if (this.invoice.payments && !Array.isArray(this.invoice.payments)) {
        return 0
      }

      return this.invoice.payments
        .filter((payment) => payment.payment_status === 'REC')
        .reduce(
          (accumulator, currentValue) =>
            (accumulator += parseFloat(currentValue.amount)),
          0
        )
    },
    awaitingPaymentAmount() {
      if (this.invoice.payments && !Array.isArray(this.invoice.payments)) {
        return 0
      }
      return this.invoice.payments
        .filter((payment) => payment.payment_status === 'AWA')
        .reduce(
          (accumulator, currentValue) => (accumulator += currentValue.amount),
          0
        )
    },
    getTotalTvac() {
      return this.invoice.total_tvac ? parseFloat(this.invoice.total_tvac) : 0
    },
    getTotalPaid() {
      return this.invoice.total_paid ? parseFloat(this.invoice.total_paid) : 0
    },
    getRest() {
      return this.getTotalTvac - this.getTotalPaid
    },
    hasRest() {
      return this.getRest > 0
    }
  },
  methods: {
    ...mapActions({
      processUpdateInvoiceAction: 'invoicing/updateInvoice',
      processSendToAccountingAction: 'invoicing/sendToAccounting'
    }),
    sendToAccounting() {
      this.processSendToAccountingAction({
        invoice_id: this.invoice.id
      }).then((response) => {
        if (response.success) {
          this.$h.toastDanger(this.$t('global.sent_to_accounting'))
        } else {
          this.$h.toastDanger(response.message)
        }
      })
    },
    updateInvoice() {
      this.processUpdateInvoiceAction({
        id: this.invoice.id,
        invoice: this.invoice
      }).then((_response) => {
        this.$h.toastSuccess(this.$t('attributes.updated'))
      })
    },
    updateInvoiceDossier(dossier_id) {
      this.linkToDossierModalOpen = false
      this.invoice.dossier_id = dossier_id
      this.updateInvoice()
    },
    async confirmLink(link) {
      let confirm = await this.$refs.invoicingShowInformationConfim.show({
        title: this.$t('invoicing.confirm_leave_invoice'),
        okButton: this.$t('global.yes')
      })
      if (confirm) {
        this.$router.push(link)
      }
    }
  }
}
</script>
