<template>
  <div class="flex flex-col gap-2">
    <h3 class="text-base font-bold capitalize-first">
      {{ $t('global.button.dashboard.agenda.view') }}
    </h3>
    <div class="flex flex-row flex-wrap gap-2">
      <BaseButton
        v-for="item in views"
        :key="item.label"
        :title="item.label"
        :icon="item.icon"
        size="small"
        :is-outline="item.type !== view"
        @click="localView = item.type"
        inverse-icon-order
      />
    </div>
    <div
      v-if="['resourceTimelineDay'].includes(this.localView)"
      class="flex flex-col"
    >
      <h3 class="text-base font-bold capitalize-first">
        {{ views.find((fView) => fView.type === localView).label }}
      </h3>
      <div class="flex flex-row flex-wrap gap-2">
        <BaseButton
          v-for="timeline_group in timeline_groups"
          :key="timeline_group.label"
          :title="timeline_group.label"
          :icon="timeline_group.icon"
          size="small"
          :is-outline="timeline_group.type !== localTimelineGroupBy"
          @click="localTimelineGroupBy = timeline_group.type"
          inverse-icon-order
        />
      </div>
    </div>
    <div
      v-if="
        ['resourceTimelineDay'].includes(this.localView) &&
        localTimelineGroupBy === 'location'
      "
      class="flex flex-col"
    >
      <h3 class="font-bold capitalize-first">
        {{ $t('button.filter') }}
      </h3>
      <div class="flex flex-row flex-wrap gap-2">
        <BaseButton
          v-for="type_location in types_location"
          :key="type_location.id"
          :title="type_location.name"
          size="extra-small"
          :is-outline="!localTimelineLocationFilter.includes(type_location.id)"
          @click="localTimelineLocationFilterToggle(type_location.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewSelector',
  components: { BaseButton, FontAwesomeIcon },
  props: {
    view: {
      type: String,
      required: true
    },
    timelineGroupBy: {
      type: String,
      required: true
    },
    timelineLocationFilter: {
      type: Array,
      required: true
    }
  },
  emits: [
    'update:view',
    'update:timelineGroupBy',
    'update:timelineLocationFilter'
  ],
  data() {
    return {
      views: [
        {
          label: this.$t('global.button.dashboard.agenda.jour'),
          icon: 'calendar-day',
          gate: true,
          type: 'timeGridDay'
        },
        {
          label: this.$t('global.button.dashboard.agenda.semaine'),
          icon: 'calendar-week',
          gate: true,
          type: 'timeGridWeek'
        },
        {
          label: this.$t('global.button.dashboard.agenda.mois'),
          icon: 'calendar-days',
          gate: true,
          type: 'dayGridMonth'
        },
        {
          label: this.$t('global.button.dashboard.agenda.timeline_jour'),
          icon: 'list-timeline',
          gate: true,
          type: 'resourceTimelineDay'
        }
      ],
      timeline_groups: [
        {
          label: this.$t('attributes.site'),
          icon: 'coffin-cross',
          gate: true,
          type: 'site'
        },
        {
          label: this.$t('attributes.location'),
          icon: 'magnifying-glass-location',
          gate: true,
          type: 'location'
        },
        {
          label: this.$t('attributes.users'),
          icon: 'users',
          gate: true,
          type: 'users'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      types_location: 'metadata/getTypesLocationMetadata'
    }),
    localView: {
      get() {
        return this.view
      },
      set(value) {
        this.$emit('update:view', value)
      }
    },
    localTimelineGroupBy: {
      get() {
        return this.timelineGroupBy
      },
      set(value) {
        this.$emit('update:timelineGroupBy', value)
      }
    },
    localTimelineLocationFilter: {
      get() {
        return this.timelineLocationFilter ?? []
      },
      set(value) {
        this.$emit('update:timelineLocationFilter', value)
      }
    }
  },
  methods: {
    localTimelineLocationFilterToggle(type) {
      this.localTimelineLocationFilter.indexOf(type) !== -1
        ? this.localTimelineLocationFilter.splice(
            this.localTimelineLocationFilter.indexOf(type),
            1
          )
        : this.localTimelineLocationFilter.push(type)
      this.$emit(
        'update:timelineLocationFilter',
        this.localTimelineLocationFilter
      )
    }
  }
}
</script>

<style scoped></style>
