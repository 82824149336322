<template>
  <div class="flex h-full flex-col">
    <div class="-mx-1 flex min-h-0 flex-1 flex-col gap-4 overflow-y-auto px-1">
      <div class="flex flex-col gap-2">
        <h3 class="text-lg font-semibold capitalize-first">
          {{ $t('navigation.content_accounting_diaries_title') }}
        </h3>
        <div class="grid grid-cols-3 gap-3 md:grid-cols-4">
          <AccountDiaryButton
            v-for="account_diary in accounting_diaries"
            :key="account_diary.id"
            :account-diary="account_diary"
            :selected="form.account_diary_id === account_diary.id"
            @click="form.account_diary_id = account_diary.id"
          />
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <h3 class="text-lg font-semibold capitalize-first">
          {{ $t('attributes.periode') }}
        </h3>
        <div class="grid grid-cols-3 gap-3 md:grid-cols-4">
          <div>
            <BaseShowEditDatePickerv2
              v-model:date="form.date_from"
              :label="$t('attributes.date_from')"
              :edition-mode="selectedAccountingDiary !== null"
              :min-date="selectedAccountingDiary?.from"
              :max-date="selectedAccountingDiary?.to"
              :extra-dots="getDotsFromAccountDiary"
              required
            />
          </div>
          <div>
            <BaseShowEditDatePickerv2
              v-model:date="form.date_to"
              :label="$t('attributes.date_to')"
              :edition-mode="selectedAccountingDiary !== null"
              :min-date="selectedAccountingDiary?.from"
              :max-date="selectedAccountingDiary?.to"
              :extra-dots="getDotsFromAccountDiary"
              required
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex shrink-0 flex-col gap-2">
      <h3 class="text-lg font-semibold capitalize-first">
        {{ $t('options') }}
      </h3>
      <div class="grid grid-cols-2 gap-3 md:grid-cols-3">
        <div>
          <BaseCheckBox
            v-model="form.mark_as_exported"
            :label="$t('global.mark_exported')"
            edition-mode
          />
        </div>
        <div>
          <BaseCheckBox
            v-model="form.include_exported"
            :label="$t('global.include_exported')"
            edition-mode
          />
        </div>
      </div>
    </div>
    <div class="flex shrink-0 flex-row-reverse gap-x-2">
      <BaseButton
        icon="file-export"
        :title="$t('global.export')"
        :disabled="!canExport"
        @click="processExport"
      />
      <BaseButton
        icon="file-zipper"
        :title="$t('global.export_pdf_list')"
        color="warning"
        :disabled="!canExportPDF || load_pdf"
        :display-loader="load_pdf"
        @click="processExportPdfFile"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import BaseCheckBox from '@c/addf-package/components/BaseCheckBox/BaseCheckBox.vue'
import { mapActions, mapGetters } from 'vuex'
import AccountDiaryButton from '@/views/invoicing/index/accountingExport/component/AccountDiaryButton.vue'

export default {
  name: 'ExportAccountingDiaries',
  components: {
    AccountDiaryButton,
    BaseCheckBox,
    BaseShowEditDatePickerv2,
    BaseButton
  },

  data() {
    return {
      accounting_diaries: [],
      error_accounting_diary: {
        date: null,
        account_diary: null
      },
      form: {
        account_diary_id: null,
        date_from: null,
        date_to: null,
        mark_as_exported: false,
        include_exported: false
      },
      load_pdf: false
    }
  },
  computed: {
    ...mapGetters({}),
    selectedAccountingDiary() {
      if (!this.form.account_diary_id) {
        return null
      }
      this.error_accounting_diary.account_diary = null
      return this.accounting_diaries.find(
        (account_diary) => account_diary.id === this.form.account_diary_id
      )
    },
    canExport() {
      return (
        this.form.account_diary_id !== null &&
        this.form.date_from !== null &&
        this.form.date_to !== null
      )
    },
    canExportPDF() {
      return this.form.date_from !== null && this.form.date_to !== null
    },
    getDotsFromAccountDiary() {
      if (!this.selectedAccountingDiary) {
        return []
      }
      let array = []
      this.selectedAccountingDiary.invoices.map((invoice) => {
        let color = invoice.exported === 1 ? 'green' : 'orange'
        let date = invoice.emission_date
        if (
          !!date &&
          !array.some((item) => item.color === color && item.date === date)
        ) {
          array.push({
            color,
            date
          })
        }
      })
      return array
    }
  },
  mounted() {
    this.fetchExportDataset()
  },
  methods: {
    ...mapActions({
      processFetchAccountingDiaryListAction:
        'invoicing/fetchAccountingDiaryListForExport',
      processExportInvoiceAction: 'invoicing/exportInvoice',
      processExportPDFInvoiceAction: 'invoicing/exportPDFInvoice'
    }),
    fetchExportDataset() {
      this.processFetchAccountingDiaryListAction().then(
        (response) => (this.accounting_diaries = response.accounting_diaries)
      )
    },
    processExport() {
      this.processExportInvoiceAction({
        data: this.form
      }).then((response) => {
        if (response.message) {
          this.$h.toastInfo(response.message)
        }
        if (response.url) {
          this.$h.downloadURI(response.url)
        }
        this.fetchExportDataset()
      })
    },
    processExportPdfFile() {
      this.load_pdf = true
      this.processExportPDFInvoiceAction({
        data: this.form
      })
        .then((response) => {
          if (response.message) {
            this.$h.toastInfo(response.message)
          }
          if (response.url) {
            this.$h.downloadURI(response.url)
          }
          this.fetchExportDataset()
        })
        .finally((_) => {
          this.load_pdf = false
        })
    }
  }
}
</script>
