<template>
  <div class="flex h-full flex-col">
    <div
      class="-mx-1 flex min-h-0 flex-1 flex-col gap-4 overflow-y-auto px-1"
    >
      <div class="flex flex-col gap-2">
        <h3 class="text-lg font-semibold capitalize-first">
          {{ $t('attributes.periode') }}
        </h3>
        <div class="grid grid-cols-3 gap-3 md:grid-cols-4">
          <div>
            <BaseShowEditDatePickerv2
              :label="$t('attributes.date_from')"
              v-model:date="form.date_from"
              edition-mode
              required
            />
          </div>
          <div>
            <BaseShowEditDatePickerv2
              :label="$t('attributes.date_to')"
              v-model:date="form.date_to"
              edition-mode
              required
            />
          </div>
          <div>
            <SelectFuneralHome
              v-model="form.funeral_home_id"
              :edition-mode="true"
              name="funeral_home_id"
             error-key=""/>
          </div>
        </div>
      </div>
      <h3 class="text-lg font-semibold capitalize-first">
        {{ $t('global.documents') }}
      </h3>
      <div class="grid grid-cols-3 gap-3 md:grid-cols-4">
        <button
          class="flex flex-row gap-2 rounded border px-3 py-2"
          @click="getImpaye"
        >
          <FontAwesomeIcon :icon="['fal', 'download']"/>
          <span>Impayés</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import {mapActions, mapGetters} from 'vuex'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import SelectFuneralHome from "@c/InitialStateFormComponent/SelectFuneralHome.vue";
import moment from 'moment'


const date_from = moment().subtract(1, 'months').format('MM/DD/YYYY');
const date_to = moment().format('MM/DD/YYYY');
export default {
  name: 'ExportDocuments',
  components: {SelectFuneralHome, BaseShowEditDatePickerv2, FontAwesomeIcon},
  data() {
    return {
      form: {
        date_from: date_from,
        date_to: date_to,
        funeral_home_id: null
      }
    }
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({
      processExportUnpaidAction: 'invoicing/exportUnpaid'
    }),
    getImpaye() {
      this.processExportUnpaidAction({
        data: this.form
      }).then((response) => {
        if (response.message) {
          this.$h.toastInfo(response.message)
        }
        if (response.url) {
          this.$h.downloadURI(response.url)
        }
      })
    }
  }
}
</script>
