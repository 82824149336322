<template>
  <tr
    :class="[
      selected ? 'bg-gray-50 text-blue-300' : '',
      invoiceable.is_deceased
        ? 'dark:text-slate-50000 text-gray-500'
        : 'text-gray-900  dark:text-white'
    ]"
  >
    <td
      class="flex cursor-pointer flex-row items-center gap-1 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6"
      @click="modalOpened = true"
    >
      <div class="rounded bg-gray-200 px-1 py-0.5 dark:text-slate-700">
        <FontAwesomeIcon :icon="['fas', invoiceableIcon]" fixed-width />
      </div>
      <div>{{ invoiceable.full_name }}</div>
      <ShowInvoiceableModal
        v-model:open="modalOpened"
        :invoiceable-id="invoiceable.id"
        :invoiceable-type="invoiceable.type"
      />
    </td>
    <td
      class="whitespace-nowrap px-3 py-4 text-sm"
      :class="
        invoiceable.is_deceased
          ? 'text-gray-500 dark:text-slate-300'
          : 'text-gray-900 dark:text-white'
      "
    >
      {{ address }}
    </td>
    <td
      class="flex flex-row justify-end gap-2 whitespace-nowrap py-4 text-center text-sm font-medium sm:pr-6"
    >
      <slot name="person-cta">
        <BaseButton
          :title="'select'"
          size="extra-small"
          :disabled="invoiceable.is_deceased"
          :color="
            invoiceable.is_deceased
              ? 'secondary'
              : 'primary dark:bg-slate-700 dark:hover:bg-primary-500 dark:border-primary-600'
          "
          @click="$emit('selection')"
        />
      </slot>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import ShowInvoiceableModal from './ShowInvoiceableModal.vue'

export default defineComponent({
  name: 'InvoiceableRow',
  components: { ShowInvoiceableModal, BaseButton, FontAwesomeIcon },
  props: {
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    invoiceable: {
      type: Object,
      required: true
    }
  },
  emits: ['selection'],
  data() {
    return {
      modalOpened: false
    }
  },
  computed: {
    address() {
      const street = this.invoiceable.street ? this.invoiceable.street : ''
      const zip = this.invoiceable.zip ? this.invoiceable.zip : ''
      const city = this.invoiceable.city ? this.invoiceable.city : ''

      return `${street}${street && zip ? ' ' : ''}${zip}${
        (street || zip) && city ? ' ' : ''
      }${city}`
    },
    invoiceableIcon() {
      if (this.invoiceable.is_deceased) {
        return 'cross'
      }
      switch (this.invoiceable.type) {
        case 'App\\Models\\Contact':
          return 'address-book'
        case 'App\\Models\\Supplier':
          return 'truck'
        case 'App\\Models\\Company':
          return 'house-blank'
        case 'App\\Models\\Person':
          return 'user'
      }
    }
  }
})
</script>
