<template>
  <div class="flex w-full flex-col space-y-3">
    <div class="flex flex-row gap-2">
      <EIDReader @data-selected="dataFromEid" hide-title />
      <h2 class="text-xl font-medium capitalize-first">
        {{ $t('global.contact') }}
      </h2>
    </div>
    <div v-if="suggestLinkToDossier" class="flex flex-row items-end space-x-2">
      <div>
        <WalkmanButton
          v-model="lier_au_dossier"
          label="lier au dossier"
          :options="[
            { value: true, label: $t('enums.yesNo.1') },
            { value: false, label: $t('enums.yesNo.0') }
          ]"
          @update:model-value="checkValidate"
        />
      </div>
      <div v-if="lier_au_dossier" class="w-64">
        <SelectRelationship
          v-model="localContact.relationship"
          edition-mode
          required
        />
      </div>
    </div>
    <div v-if="!lier_au_dossier" class="block">
      <div>
        <WalkmanButton
          v-model="ajouter_carnet_adresse"
          label="ajouter au carnet d'adresse"
          :options="[
            { value: true, label: $t('enums.yesNo.1') },
            { value: false, label: $t('enums.yesNo.0') }
          ]"
          @update:model-value="checkValidate"
        />
      </div>
      <div v-if="ajouter_carnet_adresse" class="w-64">
        <SelectContactRole
          v-model="localContact.contact_role"
          edition-mode
          required
        />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-3">
      <div class="flex flex-col gap-2">
        <div>
          <WalkmanButton
            v-model="localContact.is_company"
            :label="$t('attributes.is_company')"
            :options="[
              { value: true, label: $t('enums.yesNo.1') },
              { value: false, label: $t('enums.yesNo.0') }
            ]"
            @update:model-value="checkValidate"
          />
        </div>
        <template v-if="localContact.is_company">
          <div>
            <BaseShowEditInput
              v-model="localContact.company_name"
              :label="$t('attributes.company_name')"
              :errors="errors?.company_name"
              edition-mode
              required
            />
          </div>
          <div>
            <BaseShowEditInput
              v-model="localContact.tva"
              :label="$t('attributes.tva')"
              :errors="errors?.tva"
              edition-mode
            />
          </div>
        </template>
        <template v-else>
          <div>
            <SelectCivility
              v-model="localContact.civility"
              :errors="errors?.civility"
              edition-mode
              required
            />
          </div>
          <div>
            <BaseShowEditInput
              v-model="localContact.last_name"
              :label="$t('attributes.last_name')"
              :errors="errors?.last_name"
              edition-mode
              required
            />
          </div>
          <div>
            <BaseShowEditInput
              v-model="localContact.first_name"
              :label="$t('attributes.first_name')"
              :errors="errors?.first_name"
              edition-mode
              required
            />
          </div>
        </template>
        <div>
          <SelectLanguages
            v-model="localContact.language"
            :errors="errors?.language"
            edition-mode
            required
          />
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <div>
          <BaseShowEditAddress
            v-model:country="localContact.country"
            v-model:city-id="localContact.city_id"
            v-model:zip="localContact.zip"
            v-model:city="localContact.city"
            v-model:extra-street="localContact.extra_street"
            v-model:street="localContact.street"
            v-model:current-city-object="localContact.cityObject"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="localContact.email"
            :label="$t('attributes.email')"
            :errors="errors?.email"
            name="email"
            edition-mode
          />
        </div>
        <div>
          <BaseShowEditPhoneInput
            v-model:countryValue="localContact.mobile_country"
            v-model:numberValue="localContact.mobile_field"
            :errors="errors"
            edition-mode
            :label="$t('attributes.mobile')"
            name="mobile"
          />
        </div>
        <div>
          <BaseShowEditPhoneInput
            v-model:countryValue="localContact.phone_country"
            v-model:numberValue="localContact.phone_field"
            :errors="errors"
            edition-mode
            :label="$t('attributes.phone')"
            name="phone"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectCivility from '@c/InitialStateFormComponent/Enums/SelectCivility.vue'
import BaseShowEditPhoneInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditPhoneInput.vue'
import WalkmanButton from '@c/WalkmanButton.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import SelectRelationship from '@c/InitialStateFormComponent/Enums/SelectRelationship.vue'
import BaseShowEditAddress from '@c/BaseShowEditAddress.vue'
import SelectContactRole from '@c/InitialStateFormComponent/Enums/SelectContactRole.vue'
import { InvoiceableType } from '@/assets/enums'
import SelectLanguages from '@c/InitialStateFormComponent/Enums/SelectLanguages.vue'
import EIDReader from '@/views/layouts/top-bar/components/EIDReader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'InvoiceableForm',
  components: {
    EIDReader,
    SelectLanguages,
    SelectContactRole,
    BaseShowEditAddress,
    SelectRelationship,
    BaseShowEditInput,
    WalkmanButton,
    BaseShowEditPhoneInput,
    SelectCivility
  },
  props: {
    contact: {
      type: Object,
      required: true
    },
    suggestLinkToDossier: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:contact', 'form:is:valid'],
  data() {
    return {
      lier_au_dossier: true,
      ajouter_carnet_adresse: false
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors'
    }),
    localContact: {
      get() {
        return this.contact
      },
      set(value) {
        this.$emit('update:contact', value)
      }
    }
  },
  watch: {
    'localContact.company_name'(_newValue, _oldValue) {
      this.checkValidate()
    },
    'localContact.relationship'(_newValue, _oldValue) {
      this.checkValidate()
    },
    'localContact.civility'(_newValue, _oldValue) {
      this.checkValidate()
    },
    'localContact.contact_role'(_newValue, _oldValue) {
      this.checkValidate()
    },
    'localContact.last_name'(_newValue, _oldValue) {
      this.updateFullName()
      this.checkValidate()
    },
    'localContact.first_name'(_newValue, _oldValue) {
      this.updateFullName()
      this.checkValidate()
    },
    'localContact.language'(_newValue, _oldValue) {
      this.checkValidate()
    },
    'localContact.country'(newValue, _oldValue) {
      if (
        !this.localContact.phone_field ||
        this.localContact.phone_field.length === 0
      ) {
        this.localContact.phone_country = newValue
      }
      if (
        !this.localContact.mobile_field ||
        this.localContact.mobile_field.length === 0
      ) {
        this.localContact.mobile_country = newValue
      }
      this.checkValidate()
    },
    lier_au_dossier(_newValue, _oldValue) {
      this.updateInvoiceableType()
    },
    ajouter_carnet_adresse(_newValue, _oldValue) {
      this.updateInvoiceableType()
    }
  },
  mounted() {
    this.updateInvoiceableType()
  },
  methods: {
    updateInvoiceableType() {
      if (!this.lier_au_dossier && this.ajouter_carnet_adresse) {
        this.localContact.type = InvoiceableType.SUPPLIER
      } else {
        this.localContact.type = InvoiceableType.PERSON
      }
    },
    updateFullName() {
      this.localContact.full_name = `${this.localContact.last_name} ${this.localContact.first_name}`
    },
    isValidate() {
      return (
        (!!this.localContact.is_company &&
          !!this.localContact.company_name.length) ||
        (!this.localContact.is_company &&
          !!this.localContact.civility &&
          !!this.localContact.last_name &&
          !!this.localContact.first_name &&
          !!this.localContact.language &&
          !!this.localContact.country)
      )
    },
    checkValidate() {
      this.$emit('form:is:valid', this.isValidate())
    },
    dataFromEid(data) {
      if (this.localContact) {
        this.localContact = {
          ...this.localContact,
          ...data,
          cityObject: data.city_object
        }
      }
    }
  }
}
</script>

<style scoped></style>
